import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  setDoc,
  Timestamp,
  arrayUnion,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import "react-orgchart/index.css";
import {
  TextField,
  Box,
  Button,
  Typography,
  Autocomplete,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Menu,
} from "@mui/material";
import {
  franchiseOptions,
  brandOptions,
  US_STATES,
} from "../nightShift/constants";
import Lottie from "lottie-react";
import animationData from "../../assets/loading.json";
import { ExpandMore, ExpandLess, Edit as EditIcon } from "@mui/icons-material";
import { FaFileExcel, FaPlus } from "react-icons/fa";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import CloseIcon from "@mui/icons-material/Close";
import { MdExpandCircleDown } from "react-icons/md";
import { useAuth } from "../../contexts/AuthContext";
import { MdOutlinePerson3 } from "react-icons/md";
import { PiGarageBold } from "react-icons/pi";
import * as XLSX from "xlsx";

const Portfolio = () => {
  const { user, userPermissions, userRole, availableProperties } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [ownershipData, setOwnershipData] = useState([]);
  const [openFinancialDialog, setOpenFinancialDialog] = useState(false);
  const [openOperatingDialog, setOpenOperatingDialog] = useState(false);
  const [financialYear, setFinancialYear] = useState("");
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear() - 1,
  );
  const [isNewYear, setIsNewYear] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [isFullySaved, setIsFullySaved] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [operatingEntities, setOperatingEntities] = useState([]);
  const [loadingEntities, setLoadingEntities] = useState(false);
  const [ownershipPercentages, setOwnershipPercentages] = useState({});
  const [individuals, setIndividuals] = useState([]); // Stores existing individuals
  const [newOwners, setNewOwners] = useState([]); // Stores manually added owners
  const [newNotes, setNewNotes] = useState("");
  const [openEntityDialog, setOpenEntityDialog] = useState(false);
  const [openIndividualDialog, setOpenIndividualDialog] = useState(false);
  const [entities, setEntities] = useState([]);
  const [focusedField, setFocusedField] = useState(null);
  const [propertyCode, setPropertyCode] = useState([]);
  const [setPermissionsForProperty] = useState({});
  const [selectedPropertyCode] = useState("CHD");
  const [exportAnchorEl, setExportAnchorEl] = useState(null);

  // PERMISSIONS

  useEffect(() => {
    if (!propertyCode || !userPermissions[propertyCode]) return;

    // Avoid setting state if permissions haven't changed to prevent re-renders
    const propertyPermissions = userPermissions[propertyCode].permissions || {};
    const permissionsArray = Object.keys(propertyPermissions).filter(
      (key) => propertyPermissions[key] === true,
    );

    setPermissionsForProperty((prevPermissions) => {
      // Only update state if permissions have changed
      const permissionsChanged =
        JSON.stringify(prevPermissions) !== JSON.stringify(permissionsArray);
      return permissionsChanged ? permissionsArray : prevPermissions;
    });
  }, [propertyCode, userPermissions]);

  // Function to determine if a user can view a section
  const canViewSection = (permissions = [], propertyCode) => {
    const requiredPermissions = Array.isArray(permissions)
      ? permissions
      : [permissions];

    // Grant access to Global, MGMT, and PropertyAdmin roles
    if (["Global", "MGMT"].includes(userRole)) return true;

    // Check specific permissions for the property
    return requiredPermissions.every((permission) => {
      const propertyPermissions =
        userPermissions[propertyCode]?.permissions || {};
      return propertyPermissions[permission] === true;
    });
  };

  const [newEntity, setNewEntity] = useState({
    memberID: "", // Entity ID entered by user
    memberName: "",
    type: "ENTITY",
    creationDate: "",
    registeredAgent: "",
    state: "",
    owners: [], // Selected individuals
    notes: [],
  });
  const [newIndividual, setNewIndividual] = useState({
    memberID: "", // Unique ID for the individual
    memberName: "",
    type: "INDIVIDUAL",
    creationDate: "",
    registeredAgent: "",
    state: "",
    notes: [],
  });

  useEffect(() => {
    if (!selectedYear) return; // Prevent running if no year is selected

    const fetchAllOperatingEntities = async () => {
      setIsLoading(true);
      setError("");

      try {
        console.log("🔍 Fetching operating entities for year:", selectedYear);

        // Fetch allowed EIDs from `holdMembers`
        let allowedEIDs = new Set();

        if (["Global", "MGMT"].includes(userRole)) {
          console.log(
            "🔓 User has Global/MGMT access, no restrictions applied.",
          );
        } else {
          console.log("🔐 Fetching allowed entities for user:", user.email);
          const membersQuery = query(
            collection(db, "holdMembers"),
            where("authorizedUsers", "array-contains", user.email),
          );

          const membersSnapshot = await getDocs(membersQuery);
          allowedEIDs = new Set(
            membersSnapshot.docs.map((doc) => doc.data().memberID),
          );

          if (allowedEIDs.size === 0) {
            console.warn("⚠ No authorized entities found for this user.");
            setOwnershipData([]);
            setOperatingEntities([]);
            return;
          }
          console.log("✅ Authorized EIDs:", Array.from(allowedEIDs));
        }

        // Fetch all operating entities
        const operatingSnapshot = await getDocs(
          collection(db, "holdOperating"),
        );
        const allOperatingEntities = [];
        const uniqueEntities = new Map(); // Ensures unique results

        for (const document of operatingSnapshot.docs) {
          const operatingData = document.data();
          const operatingEntityId = document.id;
          const eid = operatingData.eid;

          // If user is not Global/MGMT, filter by allowed EIDs
          if (!["Global", "MGMT"].includes(userRole) && !allowedEIDs.has(eid)) {
            console.warn(
              `🚫 Skipping unauthorized entity: ${operatingData.operatingEntity}`,
            );
            continue;
          }

          const soldDate = operatingData.sold
            ? new Date(operatingData.sold)
            : null;
          const filterDate = new Date(selectedYear, 0, 1);
          const oneYearAgo = new Date(filterDate);
          oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

          // Exclude properties sold more than 1 year ago
          if (soldDate && soldDate < oneYearAgo) {
            continue;
          }

          // Fetch financial data with the correct ID and year filter
          const financialDataQuery = query(
            collection(db, "holdFinancials"),
            where("operatingEntity", "==", operatingEntityId),
            where("year", "==", selectedYear),
          );
          const financialDataSnapshot = await getDocs(financialDataQuery);

          let financialData = {};
          let isFullySaved = false;

          if (!financialDataSnapshot.empty) {
            financialData = financialDataSnapshot.docs[0].data();
            isFullySaved = financialData.isFullySaved || false;
          }

          // Owners are already included in holdOperating
          const owners = operatingData.owners || [];

          // Add unique entities only
          if (!uniqueEntities.has(operatingData.operatingEntity)) {
            uniqueEntities.set(operatingData.operatingEntity, {
              id: operatingEntityId,
              eid,
              name: operatingData.operatingEntity,
              owners,
            });
          }

          // Attach `id`, financial data, and owners to each entity
          allOperatingEntities.push({
            id: operatingEntityId,
            ...operatingData,
            financialData,
            owners,
            eid,
            isFullySaved,
          });
        }

        // Convert Map values to array and sort alphabetically
        const sortedEntities = Array.from(uniqueEntities.values()).sort(
          (a, b) => a.name.localeCompare(b.name),
        );

        setOwnershipData(allOperatingEntities);
        setOperatingEntities(sortedEntities);
        console.log(`✅ Fetched ${sortedEntities.length} authorized entities.`);
      } catch (err) {
        console.error("🔥 Error fetching operating entity data:", err);
        setError(err.message || "An error occurred.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllOperatingEntities();
  }, [selectedYear]);

  useEffect(() => {
    const fetchOwners = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "holdMembers")),
        );

        const fetchedIndividuals = [];
        const fetchedEntities = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();

          if (data.type === "INDIVIDUAL") {
            fetchedIndividuals.push({
              id: doc.id,
              name: data.memberName || "Unknown",
              memberID: data.memberID || "", // SSN / EIN (last 4 digits used)
            });
          } else if (data.type === "ENTITY") {
            fetchedEntities.push({
              id: doc.id,
              name: data.memberName || "Unknown",
              memberID: data.memberID || "", // Full EIN
            });
          }
        });

        setIndividuals(fetchedIndividuals);
        setEntities(fetchedEntities);
      } catch (error) {
        console.error("🔥 Error fetching owners:", error);
      }
    };

    if (openEntityDialog) fetchOwners();
  }, [openEntityDialog]);

  const fetchOperatingEntities = async () => {
    setLoadingEntities(true);
    try {
      // Fetch all ENTITY types from `holdMembers`
      const querySnapshot = await getDocs(
        query(collection(db, "holdMembers"), where("type", "==", "ENTITY")),
      );

      const entities = querySnapshot.docs.map((doc) => ({
        id: doc.data().memberID, // Use `memberID` as EIN
        name: doc.data().memberName, // Entity Name
        owners: doc.data().owners || [], // Ensure owners are included
      }));

      // Sort entities alphabetically for a clean dropdown
      const sortedEntities = entities.sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      setOperatingEntities(sortedEntities);
    } catch (error) {
      console.error("🔥 Error fetching operating entities:", error);
    } finally {
      setLoadingEntities(false);
    }
  };

  useEffect(() => {
    if (openOperatingDialog) {
      fetchOperatingEntities();
    }
  }, [openOperatingDialog]);

  const filteredData = ownershipData.filter((entity) => {
    const searchLower = searchQuery.toLowerCase();

    return (
      entity.propertyName.toLowerCase().includes(searchLower) || // Match property
      entity.eid.toLowerCase().includes(searchLower) || // Match EID
      (entity.operatingEntity &&
        entity.operatingEntity.toLowerCase().includes(searchLower)) || // Match Operating Entity
      entity.owners.some(
        (owner) =>
          owner.name.toLowerCase().includes(searchLower) || // Match Owner Name
          owner.id.toString().includes(searchLower), // Match Owner ID
      )
    );
  });

  const fetchFinancialData = async (propertyId, year) => {
    try {
      const financialDataQuery = query(
        collection(db, "holdFinancials"),
        where("operatingEntity", "==", propertyId),
        where("year", "==", year),
      );

      const financialDataSnapshot = await getDocs(financialDataQuery);

      if (!financialDataSnapshot.empty) {
        const existingData = financialDataSnapshot.docs[0].data();

        setSelectedFinancialData((prev) => ({
          ...prev,
          id: existingData.id || prev.id,
          operatingEntity: existingData.operatingEntity || prev.operatingEntity,
          year: existingData.year || year,
          appraisedValue: parseFloat(existingData.appraisedValue) || 0,
          statedValuation: parseFloat(existingData.statedValuation) || 0,
          salesRevenue: parseFloat(existingData.salesRevenue) || 0,
          netIncome: parseFloat(existingData.netIncome) || 0,
          depreciation: parseFloat(existingData.depreciation) || 0,
          lossCarriedForward: parseFloat(existingData.lossCarriedForward) || 0,
          debt: parseFloat(existingData.debt) || 0,
          debtService: parseFloat(existingData.debtService) || 0,

          ltv: existingData.ltv || "N/A",
          noi: existingData.noi || "N/A",
          dscr: existingData.dscr || "N/A",
        }));

        setIsFullySaved(existingData.isFullySaved || false);
        setIsNewYear(false);
      } else {
        // No data for selected year: Allow user to input new data
        setSelectedFinancialData((prev) => ({
          id: prev.id,
          operatingEntity: prev.operatingEntity,
          year: year,
          appraisedValue: 0,
          statedValuation: 0,
          salesRevenue: 0,
          netIncome: 0,
          depreciation: 0,
          lossCarriedForward: 0,
          debt: 0,
          debtService: 0,

          // Calculated fields should default to "N/A"
          ltv: "N/A",
          noi: "N/A",
          dscr: "N/A",
        }));

        setIsFullySaved(false);
        setIsNewYear(true);
      }
    } catch (error) {
      console.error("Error fetching financial data:", error);
      alert("Error fetching financial data. Please try again.");
    }
  };

  // Open and close dialogs
  const handleOperatingDialogOpen = async (property) => {
    setSelectedOperatingProperty(property);

    setOwnershipData((prevData) =>
      prevData.map((item) => (item.id === property.id ? { ...item } : item)),
    );

    setOpenOperatingDialog(true);
  };

  const handleOperatingDialogClose = () => {
    setOpenOperatingDialog(false);
  };

  const handleFinancialDialogOpen = async (property) => {
    console.log("Opening Financial Dialog for:", property);

    if (!property.id) {
      alert("Error: Property ID is missing!");
      return;
    }

    // Initialize dialog with current main UI year
    setFinancialYear(selectedYear);

    setSelectedFinancialData({
      id: property.id,
      appraisedValue: property.financialData?.appraisedValue || "",
      statedValuation: property.financialData?.statedValuation || "",
      salesRevenue: property.financialData?.salesRevenue || "",
      netIncome: property.financialData?.netIncome || "",
      depreciation: property.financialData?.depreciation || "",
      lossCarriedForward: property.financialData?.lossCarriedForward || "",
      debt: property.financialData?.debt || "",
      debtService: property.financialData?.debtService || "",
      ltv: property.financialData?.ltv || "",
      noi: property.financialData?.noi || "",
      dscr: property.financialData?.dscr || "",
    });

    setUnsavedChanges(false);
    setIsFullySaved(false);
    setOpenFinancialDialog(true);

    // Fetch latest financial data for the selected year
    await fetchFinancialData(property.id, selectedYear);
  };

  const handleFinancialDialogClose = () => {
    if (unsavedChanges) {
      const confirmClose = window.confirm(
        "You have unsaved changes. Are you sure you want to close?",
      );
      if (!confirmClose) return;
    }

    setOpenFinancialDialog(false);
    setUnsavedChanges(false);
  };

  // Handle field changes
  const handleOperatingFieldChange = (event) => {
    const { name, value } = event.target;
    setSelectedOperatingProperty({
      ...selectedOperatingProperty,
      [name]: value,
    });
  };

  const [selectedOperatingProperty, setSelectedOperatingProperty] = useState({
    id: "",
    propertyName: "",
    address: "",
  });

  const [selectedFinancialData, setSelectedFinancialData] = useState({
    id: "",
    appraisedValue: "",
    statedValuation: "",
    salesRevenue: "",
    netIncome: "",
    depreciation: "",
    lossCarriedForward: "",
    debt: "",
    debtService: "",
    ltv: "",
    noi: "",
    dscr: "",
  });

  const handleFinancialFieldChange = (event) => {
    const { name, value } = event.target;

    const newValue = value.replace(/[^0-9.,-]/g, ""); // Keep input filtering
    const numericValue = newValue.replace(/,/g, ""); // Remove commas for internal value

    setSelectedFinancialData((prevState) => ({
      ...prevState,
      [name]: numericValue, // Store raw number for calculations
    }));

    setUnsavedChanges(true); // Preserve unsaved changes functionality
  };

  const handleFinancialFieldFocus = (e) => {
    const { name } = e.target;
    setFocusedField(name);
  };

  const handleFinancialFieldBlur = (e) => {
    const { name, value } = e.target;
    const numericValue = parseNumber(value);
    setSelectedFinancialData((prevState) => ({
      ...prevState,
      [name]: numericValue,
    }));
    setFocusedField(null);
  };

  const handleFinancialYearChange = async (event) => {
    const newYear = event.target.value;
    setFinancialYear(newYear); // Change only the dialog year

    if (!selectedFinancialData || !selectedFinancialData.id) {
      console.error(
        "Error: No property ID available for fetching financial data.",
      );
      setIsNewYear(true);
      setIsFullySaved(false); // Ensure new data is editable
      return;
    }

    try {
      await fetchFinancialData(selectedFinancialData.id, newYear);
    } catch (error) {
      console.error("Error fetching financial data for new year:", error);
    }
  };

  const handleSaveFinancialData = async () => {
    if (!financialYear) {
      alert("Please select a year.");
      return;
    }

    if (!selectedFinancialData || !selectedFinancialData.id) {
      alert("No property selected. Please try again.");
      console.error(
        "Missing property ID in selectedFinancialData:",
        selectedFinancialData,
      );
      return;
    }

    try {
      setIsLoading(true);

      // Fetch the corresponding `eid` from holdOperating
      const operatingDocRef = doc(
        db,
        "holdOperating",
        selectedFinancialData.id,
      );
      const operatingDocSnap = await getDoc(operatingDocRef);

      let eid = null;
      if (operatingDocSnap.exists()) {
        eid = operatingDocSnap.data().eid || null; // Get `eid` if it exists
      } else {
        console.warn(
          `⚠ No matching holdOperating document found for ID: ${selectedFinancialData.id}`,
        );
      }

      // Query existing financial record
      const financialDataQuery = query(
        collection(db, "holdFinancials"),
        where("operatingEntity", "==", selectedFinancialData.id),
        where("year", "==", financialYear),
      );

      const financialDataSnapshot = await getDocs(financialDataQuery);
      let docRef;

      if (!financialDataSnapshot.empty) {
        docRef = financialDataSnapshot.docs[0].ref; // Update existing
      } else {
        docRef = doc(collection(db, "holdFinancials")); // Create new
      }

      const financialData = {
        id: selectedFinancialData.id,
        operatingEntity: selectedFinancialData.id,
        year: financialYear,
        appraisedValue: parseFloat(selectedFinancialData.appraisedValue) || 0,
        statedValuation: parseFloat(selectedFinancialData.statedValuation) || 0,
        salesRevenue: parseFloat(selectedFinancialData.salesRevenue) || 0,
        netIncome: parseFloat(selectedFinancialData.netIncome) || 0,
        depreciation: parseFloat(selectedFinancialData.depreciation) || 0,
        lossCarriedForward:
          parseFloat(selectedFinancialData.lossCarriedForward) || 0,
        debt: parseFloat(selectedFinancialData.debt) || 0,
        debtService: parseFloat(selectedFinancialData.debtService) || 0,
        ltv: calculateLTV(),
        noi: calculateNOI(),
        dscr: calculateDSCR(),
        isFullySaved:
          selectedFinancialData.debt !== 0 &&
          selectedFinancialData.netIncome !== 0 &&
          selectedFinancialData.depreciation !== 0 &&
          selectedFinancialData.lossCarriedForward !== 0 &&
          selectedFinancialData.debtService !== 0,
        eid: eid, // Attach the EID from holdOperating
      };

      await setDoc(docRef, financialData, { merge: true });

      console.log("✅ Financial data saved successfully:", financialData);

      // Update table immediately without refreshing
      setOwnershipData((prevData) =>
        prevData.map((entry) =>
          entry.id === selectedFinancialData.id
            ? { ...entry, financialData }
            : entry,
        ),
      );

      setIsFullySaved(financialData.isFullySaved);
      setOpenFinancialDialog(false);

      // Add a note to the property in holdOperating
      const financialNote = {
        date: Timestamp.now(),
        user: user.email,
        action: `Saved financial data for year ${financialYear}.`,
      };

      await updateDoc(operatingDocRef, {
        notes: arrayUnion(financialNote),
      });

      console.log("📝 Note added to property:", financialNote);
    } catch (error) {
      console.error("🔥 Error saving financial data:", error);
      alert("Error saving financial data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const calculateLTV = () => {
    const { appraisedValue, debt } = selectedFinancialData;

    // Ensure numeric values
    const parsedAppraisedValue = parseFloat(appraisedValue) || 0;
    const parsedDebt = parseFloat(debt) || 0;

    return parsedAppraisedValue && parsedDebt
      ? ((parsedDebt / parsedAppraisedValue) * 100).toFixed(2)
      : "N/A";
  };

  const calculateNOI = () => {
    const { netIncome, depreciation, lossCarriedForward } =
      selectedFinancialData;

    // Ensure numeric values
    const parsedNetIncome = parseFloat(netIncome) || 0;
    const parsedDepreciation = parseFloat(depreciation) || 0;
    const parsedLossCarriedForward = parseFloat(lossCarriedForward) || 0;

    return (
      parsedNetIncome +
      parsedDepreciation +
      parsedLossCarriedForward
    ).toFixed(2);
  };

  const calculateDSCR = () => {
    const noi = parseFloat(calculateNOI()) || 0;
    const { debtService } = selectedFinancialData;

    const parsedDebtService = parseFloat(debtService) || 0;

    return parsedDebtService ? (noi / parsedDebtService).toFixed(2) : "N/A";
  };

  const uniqueOptions = new Map(); // Use a Map to store unique values

  ownershipData.forEach((entity) => {
    // Store Property Name
    if (!uniqueOptions.has(entity.propertyName)) {
      uniqueOptions.set(entity.propertyName, {
        label: entity.propertyName, // Display property name
        searchKey: entity.propertyName.toLowerCase(), // Search by property name
        type: "property",
      });
    }

    // Store EID (showing property name when searching by EID)
    if (!uniqueOptions.has(entity.eid)) {
      uniqueOptions.set(entity.eid, {
        label: entity.propertyName, // Show property name when searching by EID
        searchKey: entity.eid.toLowerCase(), // Search by EID
        type: "property",
      });
    }

    // Store Operating Entity (showing only matching operating entities)
    if (entity.operatingEntity && !uniqueOptions.has(entity.operatingEntity)) {
      uniqueOptions.set(entity.operatingEntity, {
        label: entity.operatingEntity, // Show operating entity name
        searchKey: entity.operatingEntity.toLowerCase(), // Search by operating entity
        type: "operatingEntity",
      });
    }

    // Store Owners & their IDs
    entity.owners.forEach((owner) => {
      if (!uniqueOptions.has(owner.name)) {
        uniqueOptions.set(owner.name, {
          label: owner.name, // Display owner name
          searchKey: owner.name.toLowerCase(), // Search by owner name
          type: "owner",
        });
      }

      if (!uniqueOptions.has(owner.id)) {
        uniqueOptions.set(owner.id, {
          label: owner.name, // Show owner name when searching by ID
          searchKey: owner.id.toString(), // Search by owner ID
          type: "owner",
        });
      }
    });
  });

  // Convert Map to Array for Autocomplete
  const autocompleteOptions = Array.from(uniqueOptions.values());

  // Instant filtering of options while typing
  const filteredOptions = autocompleteOptions.filter(
    (option) =>
      searchQuery &&
      (option.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        option.searchKey.includes(searchQuery.toLowerCase())),
  );

  const handleSaveOperatingData = async () => {
    try {
      let propertyId = selectedOperatingProperty.id;

      if (!propertyId) {
        propertyId = Date.now().toString().slice(-9); // Ensure unique 9-digit ID
      }

      const docRef = doc(db, "holdOperating", propertyId);

      // Fetch the existing document to compare changes
      let previousData = {};
      let isNewProperty = true;

      const existingDoc = await getDoc(docRef);
      if (existingDoc.exists()) {
        previousData = existingDoc.data();
        isNewProperty = false;
      }

      // Track field changes (only if updating)
      let changedFields = [];
      if (!isNewProperty) {
        changedFields = Object.entries(selectedOperatingProperty)
          .filter(([key, value]) => previousData[key] !== value)
          .map(
            ([key, value]) =>
              `${key} changed from "${previousData[key] || "N/A"}" to "${value}"`,
          );
      }

      let actionMessage = isNewProperty
        ? `Added a new property`
        : `Updated property details`;

      if (selectedOperatingProperty.sold) {
        actionMessage = `Marked property as SOLD on ${selectedOperatingProperty.sold}`;
      }

      if (changedFields.length > 0) {
        actionMessage += ` | Updated Fields: ${changedFields.join(", ")}`;
      }

      // Create a note entry
      const note = {
        date: Timestamp.now(),
        user: user.email,
        action: actionMessage,
      };

      // Save to Firestore (merge for updates)
      await setDoc(
        docRef,
        {
          ...selectedOperatingProperty,
          id: propertyId, // Ensure ID is saved
          notes: arrayUnion(note), // Append new note
        },
        { merge: true },
      );

      console.log("✅ Property saved successfully:", selectedOperatingProperty);

      // **Step 2: Update `holdMembers` for the corresponding `eid`**
      const { eid, accounting } = selectedOperatingProperty;
      if (eid && accounting) {
        const holdMembersRef = doc(db, "holdMembers", eid);
        const holdMembersSnap = await getDoc(holdMembersRef);

        if (holdMembersSnap.exists()) {
          const holdMembersData = holdMembersSnap.data();
          const authorizedUsers = holdMembersData.authorizedUsers || [];

          if (!authorizedUsers.includes(accounting)) {
            // Add the accountant to authorizedUsers
            await updateDoc(holdMembersRef, {
              authorizedUsers: arrayUnion(accounting),
            });
            console.log(
              `✅ Accountant ${accounting} added to authorizedUsers in holdMembers.`,
            );
          } else {
            console.log(`ℹ️ Accountant ${accounting} is already authorized.`);
          }
        } else {
          console.warn(
            `⚠️ No matching holdMembers document found for eid: ${eid}`,
          );
        }
      }

      setOpenOperatingDialog(false);
    } catch (error) {
      console.error("🔥 Error saving property:", error);
      alert("Error saving property. Please try again.");
    }
  };

  const handleAddNote = async () => {
    if (!selectedOperatingProperty || !selectedOperatingProperty.id) {
      alert("No property selected.");
      return;
    }

    const docRef = doc(db, "holdOperating", selectedOperatingProperty.id);

    // Fetch existing document for comparison
    let previousData = {};
    const existingDoc = await getDoc(docRef);
    if (existingDoc.exists()) {
      previousData = existingDoc.data();
    }

    // Identify changed fields
    const changedFields = Object.entries(selectedOperatingProperty)
      .filter(([key, value]) => previousData[key] !== value)
      .map(
        ([key, value]) =>
          `${key} changed from "${previousData[key] || "N/A"}" to "${value}"`,
      );

    // Create the note message
    const actionMessage =
      changedFields.length > 0
        ? `Updated Fields: ${changedFields.join(", ")}`
        : "No specific details provided";

    // Create the new note
    const newNote = {
      date: Timestamp.now(),
      user: user.email,
      action: actionMessage,
    };

    try {
      // Append note to Firestore document
      await updateDoc(docRef, {
        notes: arrayUnion(newNote),
      });

      console.log("Note added successfully:", newNote);

      // Update local state to reflect changes
      setSelectedOperatingProperty((prev) => ({
        ...prev,
        notes: [...(prev.notes || []), newNote],
        newChange: "", // Clear input field
      }));
    } catch (error) {
      console.error("Error adding note:", error);
      alert("Error adding note. Please try again.");
    }
  };

  const handleAddNewProperty = () => {
    setSelectedOperatingProperty({
      id: "", // Will be generated later
      franchise: "",
      brand: "",
      propertyName: "",
      address: "",
      state: "",
      acquired: "",
      constructed: "",
      renovated: "",
      sold: "",
      operatingEntity: "",
      eid: "",
      owners: [],
      lender: "",
      notes: [],
    });

    setOpenOperatingDialog(true);
  };

  const handleOperatingEntityChange = (event) => {
    const selectedEntityName = event.target.value;
    const selectedEntity = operatingEntities.find(
      (e) => e.name === selectedEntityName,
    );

    if (!selectedEntity) return;

    setSelectedOperatingProperty((prev) => ({
      ...prev,
      operatingEntity: selectedEntity.name, // ✅ Set correct entity name
      eid: selectedEntity.id, // ✅ Set EIN from `memberID`
      owners: selectedEntity.owners, // ✅ Fetch owners directly from `holdMembers`
    }));
  };

  const handleAddNewEntity = async () => {
    if (!newEntity.memberID || !newEntity.memberName) {
      alert("Please enter a valid Entity ID (EIN) and Name.");
      return;
    }

    const entityRef = doc(db, "holdMembers", newEntity.memberID);
    const entitySnap = await getDoc(entityRef);

    if (entitySnap.exists()) {
      alert("Entity ID already exists. Please enter a unique ID.");
      return;
    }

    // 🔹 Ensure total ownership equals 100%
    const totalOwnership = newOwners.reduce(
      (sum, owner) => sum + (Number(ownershipPercentages[owner.id]) || 0),
      0,
    );

    if (totalOwnership !== 100) {
      alert(
        `Total ownership percentage must equal 100%. Currently: ${totalOwnership}%`,
      );
      return;
    }

    const formattedOwners = newOwners.map((owner) => ({
      id: owner.id,
      name: owner.name,
      ownershipPercentage: Number(ownershipPercentages[owner.id]) || 0,
      type: owner.type || "INDIVIDUAL",
    }));

    const newEntityData = {
      memberID: newEntity.memberID,
      memberName: newEntity.memberName,
      type: "ENTITY",
      creationDate: newEntity.creationDate,
      registeredAgent: newEntity.registeredAgent,
      state: newEntity.state,
      owners: formattedOwners,
      involvedEntities: [],
      notes: newNotes
        ? [{ date: Timestamp.now(), user: user.email, action: newNotes }]
        : [],
    };

    try {
      console.log("🚀 Creating new entity:", newEntityData);

      // 🔹 Store the new entity
      await setDoc(entityRef, newEntityData, { merge: true });

      console.log("✅ New entity added:", newEntityData);

      // 🔹 Fetch full ownership hierarchy from holdMembers
      const fullOwnershipHierarchy = await resolveEntityOwners(
        newEntity.memberID,
        new Set(),
      );

      console.log(
        "✅ Resolved full ownership hierarchy:",
        fullOwnershipHierarchy,
      );

      // 🔹 Update the entity with the full ownership structure
      await setDoc(
        entityRef,
        { owners: fullOwnershipHierarchy },
        { merge: true },
      );

      console.log("✅ Updated entity with full nested owners.");

      // 🔹 Update all individuals' `involvedEntities`
      await updateIndividualInvolvement(fullOwnershipHierarchy);

      console.log("✅ Updated individuals' `involvedEntities` field.");

      setOperatingEntities((prev) => [...prev, newEntityData]);
      setOpenEntityDialog(false);
    } catch (error) {
      console.error("🔥 Error adding new entity:", error);
      alert("Error adding entity. Please try again.");
    }
  };

  const resolveEntityOwners = async (entityId, visited) => {
    if (visited.has(entityId)) return [];
    visited.add(entityId);

    const entityRef = doc(db, "holdMembers", entityId);
    const entitySnap = await getDoc(entityRef);

    if (!entitySnap.exists()) {
      console.warn(`⚠️ No entity found for ID ${entityId}`);
      return [];
    }

    const entityData = entitySnap.data();
    if (!entityData.owners || entityData.owners.length === 0) {
      console.warn(`⚠️ No owners found for ENTITY ${entityId}`);
      return [];
    }

    let resolvedOwners = [];

    for (const owner of entityData.owners) {
      const ownerRef = doc(db, "holdMembers", owner.id);
      const ownerSnap = await getDoc(ownerRef);

      if (!ownerSnap.exists()) continue;

      const ownerData = ownerSnap.data();
      const ownerStructure = {
        id: owner.id,
        name: ownerData.memberName || "Unknown",
        ownershipPercentage: owner.ownershipPercentage || 0,
        type: ownerData.type,
        parentEntity: entityId, // 🔹 Maintain hierarchy
        subOwners: [],
      };

      if (ownerData.type === "ENTITY") {
        ownerStructure.subOwners = await resolveEntityOwners(owner.id, visited);
      }

      resolvedOwners.push(ownerStructure);
    }

    return resolvedOwners;
  };

  const updateIndividualInvolvement = async (fullOwnershipHierarchy) => {
    const batch = writeBatch(db);
    const individualOwners = fullOwnershipHierarchy.filter(
      (o) => o.type === "INDIVIDUAL",
    );

    for (const individual of individualOwners) {
      const individualRef = doc(db, "holdMembers", individual.id);
      const individualSnap = await getDoc(individualRef);

      if (!individualSnap.exists()) continue;

      const existingData = individualSnap.data();
      const involvedEntities = existingData.involvedEntities || [];

      // 🔹 Fetch entity details from Firestore to ensure correct name
      const parentEntityRef = doc(db, "holdMembers", individual.parentEntity);
      const parentEntitySnap = await getDoc(parentEntityRef);
      const parentEntityData = parentEntitySnap.exists()
        ? parentEntitySnap.data()
        : null;

      const newInvolvement = {
        id: individual.parentEntity,
        name: parentEntityData?.memberName || "Unknown", // ✅ Correctly fetch name from Firestore
        ownershipPercentage: individual.ownershipPercentage,
        type: "ENTITY",
      };

      // Prevent duplicates
      if (!involvedEntities.some((e) => e.id === newInvolvement.id)) {
        involvedEntities.push(newInvolvement);
      }

      batch.update(individualRef, { involvedEntities });
    }

    await batch.commit();
    console.log("✅ Updated individuals' involvedEntities field.");
  };

  const handleAddNewIndividual = async () => {
    if (!newIndividual.memberID.trim() || !newIndividual.memberName.trim()) {
      alert("Individual ID and Name are required.");
      return;
    }

    // 🔹 Check if individual ID already exists
    const individualRef = doc(db, "holdMembers", newIndividual.memberID);
    const individualSnap = await getDoc(individualRef);

    if (individualSnap.exists()) {
      alert("Individual ID already exists. Please enter a unique ID.");
      return;
    }

    const individualData = {
      ...newIndividual,
      notes: newNotes
        ? [{ date: Timestamp.now(), user: user.email, action: newNotes }]
        : [],
    };

    try {
      await setDoc(individualRef, individualData);

      console.log("✅ Individual Added:", individualData);
      setOpenIndividualDialog(false);
    } catch (error) {
      console.error("🔥 Error adding individual:", error);
      alert("Error adding individual. Please try again.");
    }
  };

  const formatCurrency = (value) => {
    if (value === undefined || value === null || isNaN(parseFloat(value)))
      return "N/A";

    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(value));
  };

  const formatPercentage = (value) => {
    if (value === undefined || value === null || isNaN(value)) return "N/A";
    return `${(value * 100).toFixed(2)}%`; // Convert decimal to percentage
  };

  const formatRatio = (value) => {
    if (value === undefined || value === null || isNaN(value)) return "N/A";

    const numericValue = parseFloat(value); // Convert string to number if needed
    if (isNaN(numericValue)) return "N/A"; // Double-check for NaN cases

    return numericValue.toFixed(2); // Ensure two decimal places
  };

  const renderFinancialData = (financialData) => {
    if (!financialData || Object.keys(financialData).length === 0) {
      return (
        <TableRow>
          <TableCell
            colSpan={9}
            style={{ ...maintableBold, textTransform: "lowercase" }}
          >
            No Data
          </TableCell>
        </TableRow>
      );
    }

    const isComplete =
      financialData.appraisedValue &&
      financialData.netIncome &&
      financialData.debt &&
      financialData.netIncome &&
      financialData.depreciation &&
      financialData.lossCarriedForward &&
      financialData.debtService;

    return (
      <>
        <TableCell
          align="left"
          size="small"
          style={{
            backgroundColor: isComplete
              ? "inherit"
              : "rgba(242, 183, 10, 0.27)",
          }}
          sx={{ ...maintableBold, textTransform: "uppercase" }}
        >
          {formatCurrency(financialData.appraisedValue)}
        </TableCell>
        <TableCell align="left" size="small" sx={{ ...maintableBold }}>
          {formatCurrency(financialData.statedValuation)}
        </TableCell>
        <TableCell align="left" size="small" sx={{ ...maintableBold }}>
          {formatCurrency(financialData.salesRevenue)}
        </TableCell>
        <TableCell align="left" size="small" sx={{ ...maintableBold }}>
          {formatCurrency(financialData.netIncome)}
        </TableCell>
        <TableCell align="left" size="small" sx={{ ...maintableBold }}>
          {formatCurrency(financialData.depreciation)}
        </TableCell>
        <TableCell align="left" size="small" sx={{ ...maintableBold }}>
          {formatCurrency(financialData.lossCarriedForward)}
        </TableCell>
        <TableCell align="left" size="small" sx={{ ...maintableBold }}>
          {formatCurrency(financialData.debt)}
        </TableCell>
        <TableCell align="left" size="small" sx={{ ...maintableBold }}>
          {formatCurrency(financialData.debtService)}
        </TableCell>
        <TableCell align="left" size="small" sx={{ ...maintableBold }}>
          {formatPercentage(financialData.ltv)} {/* Keep as percentage */}
        </TableCell>
        <TableCell align="left" size="small" sx={{ ...maintableBold }}>
          {formatCurrency(financialData.noi)}
        </TableCell>
        <TableCell align="left" size="small" sx={{ ...maintableBold }}>
          {formatRatio(financialData.dscr)}
        </TableCell>
      </>
    );
  };

  const exportToExcel = (entities, includeAllRows) => {
    const formattedData = [];

    entities.forEach((entity) => {
      // Header row for each entity
      const entityHeader = {
        "Entity Name": entity.propertyName || "Unknown Property",
        "Operating Entity": entity.operatingEntity || "Unknown Entity",
        Address: entity.address || "N/A",
        "Appraised Value": "",
        "Stated Valuation": "",
        "Sales Revenue": "",
        "Net Income": "",
        Depreciation: "",
        "Loss Carried Forward": "",
        Debt: "",
        "Debt Payment": "",
        "LTV (%)": "",
        NOI: "",
        DSCR: "",
      };
      formattedData.push(entityHeader);

      if (includeAllRows) {
        entity.owners.forEach((owner) => {
          const ownershipFraction = owner.ownershipPercentage / 100;

          formattedData.push({
            "Entity Name": `→ ${owner.name}`,
            "Operating Entity": "",
            Address: "",
            "Appraised Value": formatCurrency(
              entity.financialData?.appraisedValue * ownershipFraction,
            ),
            "Stated Valuation": formatCurrency(
              entity.financialData?.statedValuation * ownershipFraction,
            ),
            "Sales Revenue": formatCurrency(
              entity.financialData?.salesRevenue * ownershipFraction,
            ),
            "Net Income": formatCurrency(
              entity.financialData?.netIncome * ownershipFraction,
            ),
            Depreciation: formatCurrency(
              entity.financialData?.depreciation * ownershipFraction,
            ),
            "Loss Carried Forward": formatCurrency(
              entity.financialData?.lossCarriedForward * ownershipFraction,
            ),
            Debt: formatCurrency(
              entity.financialData?.debt * ownershipFraction,
            ),
            "Debt Payment": formatCurrency(
              entity.financialData?.debtService * ownershipFraction,
            ),
            "LTV (%)": formatPercentage(entity.financialData?.ltv),
            NOI: formatCurrency(entity.financialData?.noi * ownershipFraction),
            DSCR: formatRatio(entity.financialData?.dscr),
          });
        });
      }
    });

    // Create and download Excel file
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(wb, ws, "Financial Data");
    XLSX.writeFile(wb, "Financial_Summary.xlsx");
  };

  // FINANCIAL DIALOG HELPER:

  const formatNumber = (value) => {
    if (value === "" || value === null || value === undefined) return "";
    const num = Number(value);
    if (isNaN(num)) return "";
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const parseNumber = (value) => {
    if (value === "" || value === null || value === undefined) return 0;
    const num = parseFloat(value.replace(/,/g, ""));
    return isNaN(num) ? 0 : num;
  };

  const financialFields = [
    { label: "Appraised Value", name: "appraisedValue" },
    { label: "Stated Valuation", name: "statedValuation" },
    { label: "Sales Revenue", name: "salesRevenue" },
    { label: "Net Income", name: "netIncome" },
    { label: "Depreciation", name: "depreciation" },
    { label: "Loss Carried Forward", name: "lossCarriedForward" },
    { label: "Debt", name: "debt" },
    { label: "Debt Service", name: "debtService" },
  ].filter((field) => {
    if (field.name === "statedValuation") {
      return canViewSection("portViewValuation", selectedPropertyCode);
    }
    return true; // Include all other fields by default
  });

  const toggleRowExpansion = (entityId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [entityId]: !prev[entityId], // Toggle open/close state
    }));
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          borderRadius: "0.9rem",
          marginBottom: "1.8rem",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="left"
          spacing={2}
          style={{ margin: "9px" }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                marginRight: 4.5,
                marginLeft: 1.8,
                marginBottom: 2.7,
                fontFamily: "Outfit",
                letterSpacing: "0.09rem",
                fontWeight: "800",
                fontSize: "2.07rem",
                color: "#202426",
                textTransform: "lowercase",
                position: "relative",
                "::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: 0.9,
                  width: "144px",
                  height: "2.7px",
                  backgroundColor: "grey",
                },
              }}
              variant="body1"
              align="left"
            >
              Portfolio
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  padding: "0.45rem 0.9rem",
                  border: "2.07px solid black",
                  borderRadius: "18px",
                  backgroundColor: "#f9f9f9",
                  spacing: "2",
                  maxWidth: "153px",
                  minWidth: "153px",
                }}
              >
                {canViewSection("portAddNewProperty", selectedPropertyCode) && (
                  <Grid item>
                    <Tooltip title="Add New Property">
                      <IconButton
                        style={{
                          backgroundColor: "transparent",
                          border: "0",
                          color: "black",
                          fontSize: "1.26rem",
                        }}
                        onClick={handleAddNewProperty}
                      >
                        <FaPlus />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                {canViewSection("portAddNewEntity", selectedPropertyCode) && (
                  <Grid item>
                    <Tooltip title="Add New Entity">
                      <IconButton
                        style={{
                          backgroundColor: "transparent",
                          border: "0",
                          color: "#202426",
                          fontSize: "1.53rem",
                        }}
                        onClick={() => setOpenEntityDialog(true)}
                      >
                        <PiGarageBold />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                {canViewSection("portAddNewIndv", selectedPropertyCode) && (
                  <>
                    <Grid item>
                      <Tooltip title="Add New Individual">
                        <IconButton
                          style={{
                            backgroundColor: "transparent",
                            border: "0",
                            color: "#202426",
                            fontSize: "1.53rem",
                          }}
                          onClick={() => setOpenIndividualDialog(true)}
                        >
                          <MdOutlinePerson3 />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={2}>
            {isLoading ? (
              <Box>
                <Lottie
                  animationData={animationData}
                  style={{ width: "99px", height: "99px" }}
                />
              </Box>
            ) : (
              <Box sx={{ width: "99px", height: "99px" }}></Box>
            )}
          </Grid>
          <Grid item xs={6} sm={2}>
            <FormControl sx={{ ...formControl }} fullWidth>
              <InputLabel sx={{ ...selectInputLabel }}>Select Year</InputLabel>
              <Select
                value={selectedYear} // Always uses main UI year
                onChange={(e) => setSelectedYear(e.target.value)} // Doesn't affect the dialog
                label="Select Year"
                sx={{ ...bh }}
              >
                {[...Array(new Date().getFullYear() - 2009)].map((_, index) => {
                  const year = new Date().getFullYear() - index;
                  return (
                    <MenuItem
                      sx={{ ...selectMenuItem }}
                      key={year}
                      value={year}
                    >
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10} sm={4}>
            <Autocomplete
              fullWidth
              freeSolo
              options={filteredOptions} // Use dynamically filtered options
              getOptionLabel={(option) => option.label} // Show only the label
              onInputChange={(event, newValue) => {
                // Find the matching option from the list
                const selectedOption = autocompleteOptions.find(
                  (option) =>
                    option.label.toLowerCase() === newValue.toLowerCase(),
                );

                // Store searchKey (EID, Operating Entity, Property Name, Owner ID, etc.)
                if (selectedOption) {
                  setSearchQuery(selectedOption.searchKey);
                } else {
                  setSearchQuery(newValue); // Default behavior for text input
                }
              }}
              filterOptions={(options) => options} // Avoid duplicate filtering
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="FILTER x PROPERTY x OWNER x EID x ENTITY"
                  variant="outlined"
                  sx={{ ...bh }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "top",
            width: "100%",
            height: "100%",
            minHeight: "200px", // Prevent collapsing
          }}
        ></Box>
      ) : (
        <Card
          sx={{
            borderRadius: "18px",
            padding: "1.26rem",
            marginBottom: "1.8rem",
            backgroundColor: "#fcfcfc",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{ marginTop: "0.9rem", overflowX: "auto" }}
          >
            <Table sx={{ width: "100%", maxHeight: "500px", overflow: "auto" }}>
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 2,
                }}
              >
                <TableRow>
                  <TableCell align="left" sx={maintitle200header}>
                    <IconButton
                      onClick={(e) => setExportAnchorEl(e.currentTarget)}
                      sx={{
                        fontSize: "1.35rem",
                        color: "#93A683",
                        backgroundColor: "transparent",
                        padding: "9.9px",
                        border: "2.97px solid #93A683",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <FaFileExcel />
                    </IconButton>
                    <Menu
                      anchorEl={exportAnchorEl}
                      open={Boolean(exportAnchorEl)}
                      onClose={() => setExportAnchorEl(null)}
                      sx={{ borderRadius: "9px", padding: "1rem" }}
                    >
                      <MenuItem
                        sx={{ ...selectMenuItem }}
                        onClick={() => exportToExcel(filteredData, false)}
                      >
                        OPERATING ENTITIES
                      </MenuItem>
                      <MenuItem
                        sx={{ ...selectMenuItem }}
                        onClick={() => exportToExcel(filteredData, true)}
                      >
                        OPERATING ENTITIES & OWNERS
                      </MenuItem>
                    </Menu>
                  </TableCell>
                  <TableCell colSpan={1} align="left" sx={maintitle200header}>
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        letterSpacing: "0.09rem",
                        fontWeight: "800",
                        fontSize: "2.07rem",
                        color: "#202426",
                        textTransform: "lowercase",
                      }}
                      variant="body1"
                      align="left"
                    ></Typography>
                  </TableCell>
                  <Tooltip title="Appraised Valuation">
                    <TableCell align="left" sx={maintitle200header}>
                      AV
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Stated Valuation">
                    <TableCell align="left" sx={maintitle200header}>
                      SV
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Sales Revenue">
                    <TableCell align="left" sx={maintitle200header}>
                      REV
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Net Income">
                    <TableCell align="left" sx={maintitle200header}>
                      NI
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Depreciation">
                    <TableCell align="left" sx={maintitle200header}>
                      DEP
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Loss Carried Forward">
                    <TableCell align="left" sx={maintitle200header}>
                      LCF
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Mortgage Balance">
                    <TableCell align="left" sx={maintitle200header}>
                      MORT
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Mortgage Payment">
                    <TableCell align="left" sx={maintitle200header}>
                      MP
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Loan-to-value">
                    <TableCell align="left" sx={maintitle200header}>
                      LTV
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Net Operating Income">
                    <TableCell align="left" sx={maintitle200header}>
                      NOI
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Debt-service coverage ratio">
                    <TableCell align="left" sx={maintitle200header}>
                      DSCR
                    </TableCell>
                  </Tooltip>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((entity) => (
                  <React.Fragment key={entity.id}>
                    {/* Main Operating Entity Row */}
                    <TableRow>
                      <TableCell sx={{ width: "125px" }}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                          sx={{ borderRadius: "36px", overflow: "hidden" }}
                          bgcolor="#f2f2f2"
                        >
                          {canViewSection(
                            "portEditProperty",
                            selectedPropertyCode,
                          ) && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                maxWidth: "3.6rem",
                                textAlign: "center",
                              }}
                            >
                              <Tooltip title="Edit Property">
                                <IconButton
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "0",
                                    color: "#202426",
                                    fontSize: "1.26rem",
                                  }}
                                  onClick={() =>
                                    handleOperatingDialogOpen(entity)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                          {canViewSection(
                            "portAddNewFinancial",
                            selectedPropertyCode,
                          ) && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                maxWidth: "3.6rem",
                                textAlign: "center",
                              }}
                            >
                              <Tooltip title="Enter Financials">
                                <IconButton
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "0",
                                    color: "#202426",
                                    fontSize: "1.44rem",
                                  }}
                                  onClick={() =>
                                    handleFinancialDialogOpen(entity)
                                  }
                                >
                                  <HiOutlineCurrencyDollar />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              maxWidth: "3.6rem",
                              textAlign: "center",
                            }}
                          >
                            <IconButton
                              onClick={() => toggleRowExpansion(entity.id)}
                            >
                              {expandedRows[entity.id] ? (
                                <ExpandMore />
                              ) : (
                                <ExpandLess />
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell
                        align="left"
                        size="small"
                        sx={{
                          ...maintableBold,
                          textTransform: "uppercase",
                          maxWidth: "189px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {entity.propertyName}
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "0.72rem",
                              fontFamily: "Outfit",
                              letterSpacing: "0.045rem",
                              backgroundColor: "white",
                              color: "#202426",
                              textTransform: "uppercase",
                            }}
                          >
                            {entity.operatingEntity}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "0.72rem",
                              fontFamily: "Outfit",
                              letterSpacing: "0.045rem",
                              backgroundColor: "white",
                              color: "#202426",
                              textTransform: "lowercase",
                            }}
                          >
                            {entity.address}
                          </Typography>
                        </Box>
                      </TableCell>
                      {renderFinancialData(entity.financialData || {})}
                    </TableRow>

                    {/* Owner Rows (Expandable) */}
                    {expandedRows[entity.id] &&
                      entity.owners.map((owner, index) => {
                        const ownershipFraction =
                          owner.ownershipPercentage / 100;
                        return (
                          <TableRow key={owner.id}>
                            {/* Render address only for the first owner */}
                            {index === 0 ? (
                              <TableCell
                                align="left"
                                size="small"
                                sx={{
                                  ...maintable,
                                  maxWidth: "117px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  wordWrap: "break-word",
                                  backgroundColor: "white",
                                }}
                              >
                                {entity.eid} - {entity.operatingEntity}
                              </TableCell>
                            ) : (
                              <TableCell
                                align="left"
                                size="small"
                                sx={{
                                  ...maintable,
                                  maxWidth: "117px",
                                  backgroundColor: "white",
                                }}
                              />
                            )}

                            <TableCell
                              align="left"
                              size="small"
                              sx={{
                                ...maintable,
                                position: "sticky",
                                left: 0, // Keeps it pinned on the left
                                zIndex: 2, // Ensures it stays above scrolling rows
                                backgroundColor: "rgba(255, 255, 255, 0.81)", // Prevents transparency
                                maxWidth: "117px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                wordWrap: "break-word",
                              }}
                            >
                              <Tooltip title={owner.name}>
                                <Typography
                                  sx={{
                                    textTransform: "uppercase",
                                    fontSize: "0.72rem",
                                    fontFamily: "Nunito",
                                    letterSpacing: "0.045rem",
                                    fontWeight: "600",
                                    maxWidth: "162px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    wordWrap: "break-word",
                                  }}
                                  variant="body2"
                                >
                                  {formatPercentage(
                                    owner.ownershipPercentage / 100,
                                  )}{" "}
                                  - {owner.name}
                                </Typography>
                              </Tooltip>
                            </TableCell>

                            {/*APPRAISED VALUE*/}
                            <TableCell
                              align="left"
                              size="small"
                              sx={{
                                ...maintable,
                                backgroundColor: "white",
                              }}
                            >
                              {formatCurrency(
                                entity.financialData?.appraisedValue *
                                  ownershipFraction,
                              )}
                            </TableCell>
                            {/*STATED VALUE*/}
                            {canViewSection(
                              "portViewValuation",
                              selectedPropertyCode,
                            ) && (
                              <TableCell
                                align="left"
                                size="small"
                                sx={{
                                  ...maintable,
                                  backgroundColor: "white",
                                }}
                              >
                                {formatCurrency(
                                  entity.financialData?.statedValuation *
                                    ownershipFraction,
                                )}
                              </TableCell>
                            )}
                            {/*SALES REVENUE*/}
                            <TableCell
                              sx={{ ...maintable, backgroundColor: "white" }}
                            />
                            {/*NET INCOME*/}
                            <TableCell
                              align="left"
                              size="small"
                              sx={{
                                ...maintable,
                                backgroundColor: "white",
                              }}
                            >
                              {formatCurrency(
                                entity.financialData?.netIncome *
                                  ownershipFraction,
                              )}
                            </TableCell>
                            {/*DEPRECIATION*/}
                            <TableCell
                              sx={{ ...maintable, backgroundColor: "white" }}
                            />
                            {/*LOSS CARRIED FORWARD*/}
                            <TableCell
                              align="left"
                              size="small"
                              sx={{
                                ...maintable,
                                backgroundColor: "white",
                              }}
                            >
                              {formatCurrency(
                                entity.financialData?.lossCarriedForward *
                                  ownershipFraction,
                              )}
                            </TableCell>
                            {/*DEBT*/}
                            <TableCell
                              align="left"
                              size="small"
                              sx={{
                                ...maintable,
                                backgroundColor: "white",
                              }}
                            >
                              {formatCurrency(
                                entity.financialData?.debt * ownershipFraction,
                              )}
                            </TableCell>
                            {/*DEBT PAYMENT*/}
                            <TableCell
                              align="left"
                              size="small"
                              sx={{
                                ...maintable,
                                backgroundColor: "white",
                              }}
                            >
                              {formatCurrency(
                                entity.financialData?.debtService *
                                  ownershipFraction,
                              )}
                            </TableCell>
                            {/*LTV*/}
                            <TableCell
                              sx={{ ...maintable, backgroundColor: "white" }}
                              colSpan={1}
                            />
                            {/*NOI*/}
                            <TableCell
                              align="left"
                              size="small"
                              sx={{
                                ...maintable,
                                backgroundColor: "white",
                              }}
                            >
                              {formatCurrency(
                                entity.financialData?.noi * ownershipFraction,
                              )}
                            </TableCell>
                            {/*dscr*/}
                            <TableCell
                              sx={{ ...maintable, backgroundColor: "white" }}
                            />
                          </TableRow>
                        );
                      })}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Dialog for editing operating data */}
          <Dialog
            open={openOperatingDialog}
            onClose={handleOperatingDialogClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
              sx: { ...dialogMain },
            }}
          >
            <Box sx={{ ...dialogBox }}>
              <IconButton onClick={handleOperatingDialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogTitle sx={{ ...dialogTitle }}>
              {selectedOperatingProperty?.id ? "Edit Property" : "Add Property"}
            </DialogTitle>

            <DialogContent sx={{ ...dialogContent }}>
              {selectedOperatingProperty && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.9}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        fontFamily: "Outfit",
                        fontSize: "1.17rem",
                        fontWeight: "800",
                        textTransform: "lowercase",
                        letterSpacing: "0.036rem",
                        color: "#202426",
                        paddingTop: "1.8rem",
                      }}
                    >
                      HOTEL
                    </Typography>
                  </Grid>
                  {/* Franchise Dropdown */}
                  <Grid item xs={6}>
                    <FormControl
                      sx={{ ...formControl }}
                      fullWidth
                      margin="normal"
                    >
                      <InputLabel sx={{ ...selectInputLabel }}>
                        Franchise
                      </InputLabel>
                      <Select
                        value={selectedOperatingProperty.franchise || ""}
                        onChange={handleOperatingFieldChange}
                        name="franchise"
                        // disabled={!!selectedOperatingProperty.id}
                        sx={{ ...bh }}
                      >
                        {franchiseOptions.map((option) => (
                          <MenuItem
                            sx={{ ...selectMenuItem }}
                            key={option}
                            value={option}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Brand Dropdown */}
                  <Grid item xs={6}>
                    <FormControl
                      sx={{ ...formControl }}
                      fullWidth
                      margin="normal"
                    >
                      <InputLabel sx={{ ...selectInputLabel }}>
                        Brand
                      </InputLabel>
                      <Select
                        value={selectedOperatingProperty.brand || ""}
                        onChange={handleOperatingFieldChange}
                        name="brand"
                        // disabled={!!selectedOperatingProperty.id}
                        sx={{ ...bh }}
                      >
                        {brandOptions.map((option) => (
                          <MenuItem
                            sx={{ ...selectMenuItem }}
                            key={option}
                            value={option}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Property Name */}
                  <Grid item xs={12}>
                    <TextField
                      label="Property Name"
                      name="propertyName"
                      value={selectedOperatingProperty.propertyName || ""}
                      onChange={handleOperatingFieldChange}
                      fullWidth
                      margin="normal"
                      sx={{ ...bh }}
                      InputProps={{ readOnly: !!selectedOperatingProperty.id }} // Read-only if editing
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        fontFamily: "Outfit",
                        fontSize: "1.17rem",
                        fontWeight: "800",
                        textTransform: "lowercase",
                        letterSpacing: "0.036rem",
                        color: "#202426",
                        paddingTop: "1.8rem",
                      }}
                    >
                      BOX
                    </Typography>
                  </Grid>
                  {["acquired", "constructed", "renovated"].map((field) => (
                    <Grid item xs={6} sm={3} key={field}>
                      <TextField
                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                        name={field}
                        type="date"
                        value={selectedOperatingProperty[field] || ""}
                        onChange={handleOperatingFieldChange}
                        fullWidth
                        margin="normal"
                        sx={{ ...bh }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ placeholder: "" }}
                      />
                    </Grid>
                  ))}

                  {/* Editable Sold Date */}
                  <Grid item xs={6} sm={3}>
                    <TextField
                      label="Sold"
                      name="sold"
                      type="date"
                      value={selectedOperatingProperty.sold || ""}
                      onChange={handleOperatingFieldChange}
                      fullWidth
                      margin="normal"
                      sx={{ ...bh }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ placeholder: "" }}
                    />
                  </Grid>
                  {/* Address */}
                  <Grid item xs={8}>
                    <TextField
                      label="Address"
                      name="address"
                      value={selectedOperatingProperty.address || ""}
                      onChange={handleOperatingFieldChange}
                      fullWidth
                      margin="normal"
                      multiline
                      rows={1.8}
                      sx={{ ...bh, height: "100%" }}
                      InputProps={{ readOnly: !!selectedOperatingProperty.id }}
                    />
                  </Grid>
                  {/* State Dropdown */}
                  <Grid item xs={4}>
                    <FormControl
                      sx={{ ...formControl }}
                      fullWidth
                      margin="normal"
                    >
                      <InputLabel sx={{ ...selectInputLabel }}>
                        State
                      </InputLabel>
                      <Select
                        value={selectedOperatingProperty.state || ""}
                        onChange={handleOperatingFieldChange}
                        name="state"
                        // disabled={!!selectedOperatingProperty.id}
                        sx={{ ...bh }}
                      >
                        {US_STATES.map((option) => (
                          <MenuItem
                            sx={{ ...selectMenuItem }}
                            key={option}
                            value={option}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        fontFamily: "Outfit",
                        fontSize: "1.17rem",
                        fontWeight: "800",
                        textTransform: "lowercase",
                        letterSpacing: "0.036rem",
                        color: "#202426",
                        paddingTop: "1.8rem",
                      }}
                    >
                      OPERATING ENTITY
                    </Typography>
                  </Grid>
                  {/* Operating Entity (Dropdown if Adding, Read-only if Editing) */}
                  <Grid item xs={6}>
                    {!!selectedOperatingProperty.id ? (
                      <TextField
                        label="Operating Entity"
                        name="operatingEntity"
                        value={selectedOperatingProperty.operatingEntity || ""}
                        fullWidth
                        margin="normal"
                        sx={{ ...bh }}
                        InputProps={{ readOnly: true }}
                      />
                    ) : (
                      <FormControl
                        sx={{ ...formControl }}
                        fullWidth
                        margin="normal"
                      >
                        <InputLabel sx={{ ...selectInputLabel }}>
                          Operating Entity
                        </InputLabel>
                        <Select
                          value={
                            selectedOperatingProperty.operatingEntity || ""
                          }
                          onChange={handleOperatingEntityChange}
                          name="operatingEntity"
                          disabled={loadingEntities}
                          sx={{ ...bh }}
                        >
                          {operatingEntities.map((option) => (
                            <MenuItem
                              sx={{ ...selectMenuItem }}
                              key={option.id}
                              value={option.name}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  {/* EIN - Auto-Filled */}
                  <Grid item xs={6}>
                    <TextField
                      label="EIN"
                      name="eid"
                      value={selectedOperatingProperty.eid || ""}
                      fullWidth
                      margin="normal"
                      sx={{ ...bh }}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  {/* Owners (Read-only) */}
                  <Grid item xs={12}>
                    <TextField
                      label="Owners"
                      name="owners"
                      value={(selectedOperatingProperty.owners || [])
                        .map(
                          (owner) =>
                            `${owner.name} (${owner.ownershipPercentage}%)`,
                        )
                        .join(", ")}
                      fullWidth
                      margin="normal"
                      sx={{ ...bh }}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        marginLeft: 1,
                        fontFamily: "Outfit",
                        fontSize: "1.17rem",
                        fontWeight: "800",
                        textTransform: "lowercase",
                        letterSpacing: "0.036rem",
                        color: "#202426",
                        paddingTop: "1.8rem",
                      }}
                    >
                      accounting email
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Accountant"
                      name="accounting"
                      value={selectedOperatingProperty.accounting || ""}
                      onChange={handleOperatingFieldChange}
                      fullWidth
                      margin="normal"
                      sx={{ ...bh }}
                    />
                  </Grid>
                  {/* Notes Accordion */}
                  <Grid item xs={12}>
                    {selectedOperatingProperty?.id ? (
                      <>
                        <Accordion
                          sx={{
                            backgroundColor: "white",
                            marginTop: "3.6rem",
                          }}
                        >
                          <AccordionSummary expandIcon={<MdExpandCircleDown />}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: "Outfit",
                                fontSize: "1.17rem",
                                fontWeight: "800",
                                textTransform: "lowercase",
                                letterSpacing: "0.045rem",
                              }}
                            >
                              ADD NOTE
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              {/* Notes Section */}
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Add notes for this property"
                                  variant="outlined"
                                  name="newChange"
                                  multiline
                                  rows={3.6}
                                  sx={{ ...bh, height: "100%" }}
                                />
                              </Grid>
                              {/* Add Note Button */}
                              <Grid
                                item
                                xs={12}
                                container
                                justifyContent="flex-end"
                              >
                                <Button
                                  variant="contained"
                                  onClick={handleAddNote}
                                  sx={{ ...outlineButton }}
                                >
                                  ADD NOTE
                                </Button>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          sx={{
                            backgroundColor: "#f6f6f6",
                            marginBottom: "3.6rem",
                          }}
                        >
                          <AccordionSummary expandIcon={<MdExpandCircleDown />}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: "Outfit",
                                fontSize: "1.17rem",
                                fontWeight: "800",
                                textTransform: "lowercase",
                                letterSpacing: "0.045rem",
                                color: "grey",
                              }}
                            >
                              VIEW NOTES
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              {selectedOperatingProperty.notes?.map(
                                (note, index) => (
                                  <Grid item xs={12} key={index}>
                                    <Typography variant="body2">
                                      {note.date.toDate().toLocaleString()} -{" "}
                                      {note.user}: {note.action}
                                    </Typography>
                                  </Grid>
                                ),
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                fullWidth
                onClick={handleSaveOperatingData}
                sx={{ ...greenButton }}
              >
                {selectedOperatingProperty?.id ? "SAVE" : "ADD PROPERTY"}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog for entering financial data */}
          <Dialog
            open={openFinancialDialog}
            onClose={handleFinancialDialogClose}
            fullWidth={true}
            maxWidth={"sm"}
            PaperProps={{
              sx: {
                ...dialogMain,
              },
            }}
          >
            <Box sx={{ ...dialogBox }}>
              <IconButton onClick={handleFinancialDialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogTitle sx={{ ...dialogTitle }}>
              Edit Financial Data
            </DialogTitle>
            <DialogContent
              sx={{
                ...dialogContent,
              }}
            >
              {selectedFinancialData && (
                <>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Grid item xs={12} sx={{ marginBottom: "1.8rem" }}>
                      {/* Year Selector */}
                      <FormControl
                        sx={{ ...formControl, maxWidth: "153px" }}
                        margin="normal"
                      >
                        <InputLabel
                          sx={{
                            ...selectInputLabel,
                          }}
                        >
                          Year
                        </InputLabel>
                        <Select
                          value={financialYear || ""}
                          onChange={handleFinancialYearChange} // Changes only the dialog year
                          label="Year"
                          sx={{
                            ...bh,
                          }}
                        >
                          {[...Array(50)].map((_, index) => {
                            const year = 2000 + index;
                            return (
                              <MenuItem
                                key={year}
                                value={year}
                                disabled={year > new Date().getFullYear()}
                                sx={{ ...selectMenuItem }}
                              >
                                {year}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {financialFields.map((field) => (
                      <Grid item xs={6} key={field.name}>
                        <TextField
                          label={field.label}
                          name={field.name}
                          value={
                            focusedField === field.name
                              ? selectedFinancialData[field.name]
                              : formatNumber(selectedFinancialData[field.name])
                          }
                          onChange={handleFinancialFieldChange} // Use the modified onChange
                          onFocus={handleFinancialFieldFocus}
                          onBlur={handleFinancialFieldBlur}
                          fullWidth
                          margin="normal"
                          // InputProps={{ readOnly: isFullySaved }}
                          sx={{ ...bh }}
                          type="text"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9.,-]*",
                          }}
                        />
                      </Grid>
                    ))}
                    {/* Auto-Calculated Fields */}
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          maxWidth: "3.6rem",
                          textAlign: "center",
                          marginTop: "2.7rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "0.99rem" },

                            fontWeight: 400,
                            letterSpacing: "0.045rem",
                          }}
                        >
                          {calculateLTV(selectedFinancialData)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          maxWidth: "3.6rem",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "0.99rem" },
                            fontFamily: "Outfit",
                            fontWeight: 600,
                            letterSpacing: "0.045rem",
                            color: "grey",
                          }}
                        >
                          LTV
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          maxWidth: "3.6rem",
                          textAlign: "center",
                          marginTop: "2.7rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "0.99rem" },

                            fontWeight: 400,
                            letterSpacing: "0.045rem",
                          }}
                        >
                          {calculateNOI(selectedFinancialData)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          maxWidth: "3.6rem",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "0.99rem" },
                            fontFamily: "Outfit",
                            fontWeight: 600,
                            letterSpacing: "0.045rem",
                            color: "grey",
                          }}
                        >
                          NOI
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          maxWidth: "3.6rem",
                          textAlign: "center",
                          marginTop: "2.7rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "0.99rem" },

                            fontWeight: 400,
                            letterSpacing: "0.045rem",
                          }}
                        >
                          {calculateDSCR(selectedFinancialData)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          maxWidth: "3.6rem",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "0.99rem" },
                            fontFamily: "Outfit",
                            fontWeight: 600,
                            letterSpacing: "0.045rem",
                            color: "grey",
                          }}
                        >
                          DSCR
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </DialogContent>
            <DialogActions>
              {!isFullySaved ? (
                <Button
                  fullWidth
                  onClick={handleSaveFinancialData}
                  sx={{ ...greenButton }}
                >
                  Save
                </Button>
              ) : (
                canViewSection(
                  "portEditSubmitFinancial",
                  selectedPropertyCode,
                ) && (
                  <Button
                    onClick={() => setIsFullySaved(false)}
                    sx={{ ...redButton }}
                  >
                    Edit
                  </Button>
                )
              )}
            </DialogActions>
          </Dialog>
          {/* Dialog for adding entity data */}
          <Dialog
            open={openEntityDialog}
            onClose={() => setOpenEntityDialog(false)}
            fullWidth
            maxWidth="sm"
            PaperProps={{
              sx: { ...dialogMain },
            }}
          >
            <Box sx={{ ...dialogBox }}>
              <IconButton onClick={() => setOpenEntityDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogTitle sx={{ ...dialogTitle }}>Add New Entity</DialogTitle>
            <DialogContent sx={{ ...dialogContent }}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={0.9}
              >
                <Grid item xs={12}>
                  <TextField
                    label="EIN"
                    value={newEntity.memberID}
                    onChange={(e) => {
                      let newValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      setNewEntity({ ...newEntity, memberID: newValue }); // Store only numbers
                    }}
                    fullWidth
                    margin="normal"
                    sx={{ ...bh }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Entity Name"
                    value={newEntity.memberName}
                    onChange={(e) =>
                      setNewEntity({ ...newEntity, memberName: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    sx={{ ...bh }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Creation Date"
                    type="date"
                    value={newEntity.creationDate}
                    onChange={(e) =>
                      setNewEntity({
                        ...newEntity,
                        creationDate: e.target.value,
                      })
                    }
                    fullWidth
                    margin="normal"
                    sx={{ ...bh }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ placeholder: "" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    sx={{ ...formControl }}
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel sx={{ ...selectInputLabel }}>State</InputLabel>
                    <Select
                      sx={{ ...bh }}
                      value={newEntity.state}
                      onChange={(e) =>
                        setNewEntity({ ...newEntity, state: e.target.value })
                      }
                    >
                      {US_STATES.map((state) => (
                        <MenuItem
                          sx={{ ...selectMenuItem }}
                          key={state}
                          value={state}
                        >
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Registered Agent"
                    value={newEntity.registeredAgent}
                    onChange={(e) =>
                      setNewEntity({
                        ...newEntity,
                        registeredAgent: e.target.value,
                      })
                    }
                    fullWidth
                    margin="normal"
                    sx={{ ...bh }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      marginLeft: 1,
                      fontFamily: "Outfit",
                      fontSize: "1.17rem",
                      fontWeight: "800",
                      textTransform: "lowercase",
                      letterSpacing: "0.036rem",
                      color: "#202426",
                      paddingTop: "1.8rem",
                    }}
                  >
                    ownership
                  </Typography>
                </Grid>

                {/* Add Owners Section */}
                {/* INDIVIDUAL OWNERS SELECTOR */}
                <Grid item xs={6}>
                  <FormControl
                    sx={{ ...formControl }}
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel sx={{ ...selectInputLabel }}>
                      Select Individual Owners
                    </InputLabel>
                    <Select
                      sx={{ ...bh }}
                      multiple
                      value={newOwners
                        .filter((owner) =>
                          individuals.some((ind) => ind.id === owner.id),
                        )
                        .map((owner) => owner.id)}
                      onChange={(event) => {
                        const selectedIds = event.target.value;
                        const selectedOwners = selectedIds.map((id) => {
                          const foundIndividual = individuals.find(
                            (ind) => ind.id === id,
                          );

                          return {
                            id: id,
                            name: foundIndividual?.name || "Unknown",
                            memberID: foundIndividual?.memberID || "",
                            ownershipPercentage: ownershipPercentages[id] || 0,
                          };
                        });

                        setNewOwners((prev) => [
                          ...prev.filter(
                            (owner) =>
                              !individuals.some((ind) => ind.id === owner.id),
                          ), // Keep non-individual owners
                          ...selectedOwners,
                        ]);
                      }}
                      renderValue={(selected) =>
                        selected
                          .map((id) => {
                            const owner = individuals.find(
                              (ind) => ind.id === id,
                            );
                            const last4EIN =
                              owner?.memberID?.slice(-4) || "XXXX";
                            return `${owner?.name} (${last4EIN})`;
                          })
                          .join(", ")
                      }
                    >
                      {individuals
                        .slice() // Clone the array to avoid modifying the original state
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                        .map((ind) => (
                          <MenuItem
                            sx={{ ...selectMenuItem }}
                            key={ind.id}
                            value={ind.id}
                          >
                            {ind.name} (
                            {ind.memberID ? ind.memberID.slice(-4) : "XXXX"})
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {/* ENTITY OWNERS SELECTOR */}
                  <FormControl
                    sx={{ ...formControl }}
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel sx={{ ...selectInputLabel }}>
                      Select Entity Owners
                    </InputLabel>
                    <Select
                      sx={{ ...bh }}
                      multiple
                      value={newOwners
                        .filter((owner) =>
                          entities.some((ent) => ent.id === owner.id),
                        )
                        .map((owner) => owner.id)}
                      onChange={(event) => {
                        const selectedIds = event.target.value;
                        const selectedOwners = selectedIds.map((id) => {
                          const foundEntity = entities.find(
                            (ent) => ent.id === id,
                          );

                          return {
                            id: id,
                            name: foundEntity?.name || "Unknown",
                            memberID: foundEntity?.memberID || "",
                            ownershipPercentage: ownershipPercentages[id] || 0,
                          };
                        });

                        setNewOwners((prev) => [
                          ...prev.filter(
                            (owner) =>
                              !entities.some((ent) => ent.id === owner.id),
                          ), // Keep non-entity owners
                          ...selectedOwners,
                        ]);
                      }}
                      renderValue={(selected) =>
                        selected
                          .map((id) => {
                            const owner = entities.find((ent) => ent.id === id);
                            return `${owner?.name} (EIN: ${owner?.memberID})`;
                          })
                          .join(", ")
                      }
                    >
                      {entities
                        .slice() // Clone the array to avoid modifying the original state
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                        .map((ent) => (
                          <MenuItem
                            sx={{ ...selectMenuItem }}
                            key={ent.id}
                            value={ent.id}
                          >
                            {ent.name} (EIN: {ent.memberID})
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {/* Add Ownership Percentages */}
                  {newOwners.map((owner, index) => (
                    <TextField
                      key={owner.id}
                      label={`% - ${owner.name}`}
                      type="text" // Use text to manually control input
                      value={ownershipPercentages[owner.id] || ""}
                      onChange={(e) => {
                        let newValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        newValue = Math.min(100, Math.max(0, Number(newValue))); // Ensure value is between 0 and 100

                        setOwnershipPercentages((prev) => ({
                          ...prev,
                          [owner.id]: newValue,
                        }));
                      }}
                      fullWidth
                      margin="normal"
                      sx={{ ...bh, maxWidth: "153px", margin: "9px" }}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                onClick={handleAddNewEntity}
                sx={{ ...greenButton }}
              >
                Add Entity
              </Button>
            </DialogActions>
          </Dialog>
          {/* Dialog for adding individual data */}
          <Dialog
            open={openIndividualDialog}
            onClose={() => setOpenIndividualDialog(false)}
            fullWidth
            maxWidth="sm"
            PaperProps={{
              sx: { ...dialogMain },
            }}
          >
            <Box sx={{ ...dialogBox }}>
              <IconButton onClick={() => setOpenIndividualDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogTitle sx={{ ...dialogTitle }}>
              Add New Individual
            </DialogTitle>
            <DialogContent sx={{ ...dialogContent }}>
              <TextField
                label="SSN"
                value={newIndividual.memberID}
                onChange={(e) => {
                  let newValue = e.target.value.replace(/[^0-9]/g, "");
                  setNewIndividual({
                    ...newIndividual,
                    memberID: newValue,
                  });
                }}
                fullWidth
                margin="normal"
                sx={{ ...bh }}
              />
              <TextField
                label="FULL NAME"
                value={newIndividual.memberName}
                onChange={(e) =>
                  setNewIndividual({
                    ...newIndividual,
                    memberName: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                sx={{ ...bh }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                onClick={handleAddNewIndividual}
                sx={{ ...greenButton }}
              >
                Add Individual
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      )}
    </div>
  );
};

export default Portfolio;

const maintitle200header = {
  fontWeight: 600,
  fontSize: "0.9rem",
  fontFamily: "Outfit",
  letterSpacing: "0.045rem",
  backgroundColor: "#f5f5f5",
  color: "#202426",
  textTransform: "uppercase",
};
const maintableBold = {
  textTransform: "lowercase",
  backgroundColor: "#fcfcfc",
  color: "#202426",
  rowHeight: "0.1rem",
  fontFamily: "Nunito",
  letterSpacing: "0.045rem",
  fontWeight: "800",
  fontSize: "0.81rem",
};
const maintable = {
  textTransform: "lowercase",
  backgroundColor: "#fcfcfc",
  color: "#202426",
  rowHeight: "0.1rem",
  letterSpacing: "0.036rem",
  fontWeight: "400",
  fontSize: "0.81rem",
};

const bh = {
  color: "#202426",
  height: "54px",
  fontFamily: "Nunito",
  textTransform: "uppercase",
  backgroundColor: "rgba(255, 255, 255, 0.63)",
  borderRadius: "9px",
  transition: "background-color 0.3s ease, border-color 0.3s ease",

  "& .MuiAutocomplete-inputRoot, & .MuiOutlinedInput-root": {
    height: "100%",
    fontSize: "0.9rem",
    textTransform: "uppercase",
    color: "#202426",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    borderRadius: "inherit",
    transition: "background-color 0.3s ease, border-color 0.3s ease",
  },
  ".MuiInputLabel-root": {
    fontSize: "0.81rem",
    fontFamily: "Outfit",
    fontWeight: 400,
    color: "#202426",
    transition: "0.3s",
    textTransform: "uppercase",
  },

  ".MuiInputBase-input": {
    fontFamily: "Nunito",
    fontWeight: 400,
    letterSpacing: "0.018rem",
    color: "black",
    transition: "0.3s",
    textTransform: "uppercase",
  },

  "& .MuiOutlinedInput-root.Mui-focused": {
    backgroundColor: "#ffffff",
    borderColor: "#202426",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.45)",
    borderRadius: "9px",
  },
  "&:hover": {
    backgroundColor: "#f2f2f2", // Subtle hover effect
    color: "#202426",
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },

  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },

  "& .MuiSelect-select": {
    padding: "11px 14px", // Align padding with text fields
    color: "#202426",
    fontSize: "0.99rem",
  },

  "& .MuiSelect-icon": {
    color: "#202426",
  },
};

const formControl = {
  width: "100%", // Ensures full width
  height: "54px", // Matches `bh` height
  backgroundColor: "rgba(255, 255, 255, 0.63)",
  borderRadius: "9px",
  transition: "background-color 0.3s ease, border-color 0.3s ease",

  "& .MuiOutlinedInput-root": {
    height: "100%", // Ensures consistency
    fontSize: "0.9rem",
    textTransform: "uppercase",
    color: "#202426",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    borderRadius: "inherit",
    transition: "background-color 0.3s ease, border-color 0.3s ease",
  },

  "& .MuiOutlinedInput-root.Mui-focused": {
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    borderColor: "#202426",
  },

  "& .MuiInputLabel-root": {
    fontSize: "0.81rem",
    fontFamily: "Outfit",
    fontWeight: 400,
    color: "#202426",
    transition: "color 0.3s ease, transform 0.3s ease",
    textTransform: "uppercase",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.45)",
    borderRadius: "9px",
  },

  "&:hover": {
    backgroundColor: "#f2f2f2",
    color: "#202426",
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },

  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },

  "& .MuiSelect-select": {
    padding: "11px 14px", // Align padding with text fields
    color: "#202426",
    fontSize: "0.99rem",
  },

  "& .MuiSelect-icon": {
    color: "#202426",
  },
};

const selectInputLabel = {
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  textTransform: "uppercase",
  transition: "color 0.3s ease, transform 0.3s ease",

  "&.Mui-focused": {
    color: "#202426", // Keep the label color on focus
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.45)",
    borderRadius: "9px",
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },
};

const selectMenuItem = {
  fontSize: "0.9rem",
  fontFamily: "Nunito",
  fontWeight: 400,
  letterSpacing: "0.045rem",
  color: "#202426",
  textTransform: "uppercase",
  transition: "color 0.3s ease, background-color 0.3s ease",
  borderRadius: "9px",

  "&:hover": {
    backgroundColor: "#f2f2f2",
    color: "#202426",
  },

  "&.Mui-selected": {
    backgroundColor: "#e0e0e0 !important",
    color: "#202426",
  },

  "&.Mui-focusVisible": {
    backgroundColor: "#e0e0e0",
    outline: "none",
  },
};

const dialogMain = {
  padding: "1rem",
  borderRadius: "36px",
  backgroundColor: "#f5f5f5",
};
const dialogBox = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  top: 9,
  right: 9,
  backgroundColor: "#f5f5f5",
  padding: "0.9rem",
};
const dialogTitle = {
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  fontWeight: "800",
  fontSize: "2.07rem",
  textTransform: "lowercase",
  color: "black",
  backgroundColor: "transparent",
  borderRadius: "18px",
};
const dialogContent = {
  fontFamily: "Outfit",
  letterSpacing: "0.4rem",
  fontWeight: "400",
  fontSize: "1rem",
};

const greenButton = {
  padding: "18px",
  borderRadius: "9px",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  border: "2.7px solid #93A683",
  backgroundColor: "#93A683",
  color: "#f5f5f5",
  fontWeight: "400",
  margin: "10.8",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
const redButton = {
  border: "2.7px solid #BB2E33",
  backgroundColor: "#BB2E33",
  color: "#f5f5f5",
  borderRadius: "9px",
  padding: "9px 18px 9px 18px",
  fontWeight: "400",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  margin: "10.8",
  "&:hover": {
    border: "2.7px solid #202426",
    backgroundColor: "transparent",
    color: "#202426",
  },
};
const outlineButton = {
  backgroundColor: "transparent",
  borderRadius: "9px",
  padding: "9px 18px 9px 18px",
  color: "#202426",
  fontFamily: "Outfit",
  fontWeight: "400",
  fontSize: "0.81rem",
  border: "2.7px solid #202426",
  marginRight: "0.45rem",
  "&:hover": {
    backgroundColor: "lightgrey",
    border: "2.7px solid lightgrey",
    color: "#202426",
  },
};
