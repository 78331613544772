import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  Typography,
  ListItemButton,
  ListItemIcon,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CiMenuKebab } from "react-icons/ci";
import "../components/App.css";
import FeedbackModal from "./feedback";
import logo from "../assets/bhlogo.png";
import HRIcon from "../assets/ico/SVG/profile.svg";
import TimecardsIcon from "../assets/ico/SVG/pay.svg";
import breakRm from "../assets/ico/SVG/breakRoom.svg";
import LogoutIcon from "../assets/ico/SVG/logout.svg";

const actions = [
  {
    icon: (
      <img
        src={HRIcon}
        alt="HR"
        className="nav-icon"
        style={{
          width: "1.26rem",
          height: "1.26rem",
        }}
      />
    ),
    name: "MGMT",
    subActions: [
      { name: "HR", path: "/update-Record" },
      { name: "PORTFOLIO", path: "/portfolio" },
      { name: "HOLDINGS", path: "/orgChart" },
      { name: "APPS", path: "/apps" },
      // { name: "STATS", path: "/stats" },
    ],
  },
  {
    icon: (
      <img
        src={TimecardsIcon}
        alt="PAYROLL"
        className="nav-icon"
        style={{
          width: "1.26rem",
          height: "1.26rem",
        }}
      />
    ),
    name: "PAYROLL",
    subActions: [
      { name: "PUNCHES", path: "/punch" },
      { name: "SUMMARY", path: "/summary" },
      { name: "STUBS", path: "/stubs" },
      { name: "ROSTER", path: "/roster" },
    ],
  },
  {
    icon: (
      <img
        src={breakRm}
        alt="BreakRm"
        className="nav-icon"
        style={{
          width: "1.26rem",
          height: "1.26rem",
        }}
      />
    ),
    name: "BREAKRM",
    subActions: [{ name: "BREAKRM", path: "/breakRm" }],
  },
];

const Nav = () => {
  const { user, userPermissions, userRole, logout } = useAuth();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [filteredActions, setFilteredActions] = useState([]);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState("");

  const toggleDrawer = useCallback(() => setDrawerOpen((prev) => !prev), []);
  const toggleFeedbackModal = useCallback(
    () => setFeedbackOpen((prev) => !prev),
    [],
  );

  useEffect(() => {
    console.log("useEffect triggered");

    const updateFilteredActions = (role, permissions) => {
      if (role === "Global") {
        setFilteredActions(actions); // Full access for Global role
      } else {
        const updatedActions = actions.map((action) => {
          const allowedSubActions = action.subActions.filter((subAction) =>
            Object.keys(permissions).some((propertyCode) => {
              const propertyPermissions =
                permissions[propertyCode]?.permissions || {};
              return propertyPermissions[subAction.name] === true;
            }),
          );

          return {
            ...action,
            subActions: allowedSubActions,
            disabled: allowedSubActions.length === 0, // Disable main button if no access
          };
        });

        setFilteredActions(updatedActions);
      }
    };

    if (user && userRole) {
      updateFilteredActions(userRole, userPermissions);
    } else {
      setFilteredActions([]);
    }
  }, [user, userRole, userPermissions]);

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const renderDrawer = () => (
    <SwipeableDrawer
      open={drawerOpen}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      PaperProps={{
        style: { backgroundColor: "transparent", boxShadow: "none" },
      }}
    >
      <List
        className={`l-navbar ${!drawerOpen ? "l-navbar-closed" : ""}`}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <div sx={{ flexGrow: 1 }}>
          {filteredActions.map((action) => (
            <div key={action.name}>
              <ListItemButton
                onClick={
                  () =>
                    action.path
                      ? handleNavigation(action.path) // Direct navigation for actions with a path
                      : setOpenSubMenu(
                          openSubMenu === action.name ? "" : action.name,
                        ) // Toggle submenu for others
                }
                sx={{
                  backgroundColor:
                    openSubMenu === action.name ? "transparent" : "transparent",
                  opacity: action.disabled ? 0.5 : 1, // Make disabled icons faded
                  pointerEvents: action.disabled ? "none" : "auto", // Prevent clicking
                  cursor: action.disabled ? "not-allowed" : "pointer",
                }}
              >
                <div className="icon-container">
                  <ListItemIcon sx={{ justifyContent: "center" }}>
                    {React.cloneElement(action.icon, { className: "nav-icon" })}
                  </ListItemIcon>
                  <Typography
                    variant="caption"
                    component="div"
                    className={`icon-title ${
                      action.path === window.location.pathname ? "active" : ""
                    }`}
                    sx={{
                      fontWeight: "800",
                      fontSize: "0.81rem",
                      fontFamily: "Outfit",
                      letterSpacing: "0.045rem",
                      color: "#202426",
                      textDecoration:
                        openSubMenu === action.name ? "underline" : "none",
                      textDecorationThickness: "1.08px",
                      textDecorationColor: "#202426",
                      textUnderlineOffset: "5.4px",
                    }}
                  >
                    {action.name}
                  </Typography>
                </div>
              </ListItemButton>
              {/* Render subActions only if action does not have a direct path */}
              {!action.path &&
                openSubMenu === action.name &&
                action.subActions.map((subAction) => (
                  <ListItemButton
                    key={subAction.name}
                    onClick={() => handleNavigation(subAction.path)}
                    sx={{
                      backgroundColor:
                        subAction.path === window.location.pathname
                          ? "rgba(242, 183, 10, 0.63)"
                          : "transparent",
                      transition: "background-color 0.3s ease", // Smooth background transition
                      "&:hover": {
                        backgroundColor: "lightgrey", // Hover effect
                      },
                    }}
                  >
                    <div className="icon-container">
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{
                          fontWeight: "800",
                          fontSize: "0.9rem",
                          fontFamily: "Outfit",
                          letterSpacing: "0.045rem",
                          color:
                            subAction.path === window.location.pathname
                              ? "#f5f5f5"
                              : "#202426", // Change text color for active
                          textTransform: "lowercase",
                        }}
                      >
                        {subAction.name}
                      </Typography>
                    </div>
                  </ListItemButton>
                ))}
            </div>
          ))}
        </div>
        {user && (
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <ListItemButton onClick={logout}>
              <div className="icon-container">
                <ListItemIcon sx={{ justifyContent: "center" }}>
                  <img
                    src={LogoutIcon}
                    alt="Logout Icon"
                    className="nav-icon"
                    style={{
                      width: "1.26rem",
                      height: "1.26rem",
                    }}
                  />
                </ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: "800",
                    fontSize: "0.81rem",
                    fontFamily: "Outfit",
                    letterSpacing: "0.045rem",
                    color: "#202426",
                  }}
                  variant="caption"
                  component="div"
                  className="icon-title"
                >
                  CLOCK OUT
                </Typography>
              </div>
            </ListItemButton>
          </div>
        )}
      </List>
      <FeedbackModal
        open={feedbackOpen}
        handleClose={toggleFeedbackModal}
        user={user}
      />
    </SwipeableDrawer>
  );

  const renderAppBar = () => (
    <>
      <header className="navbar no-print">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <CiMenuKebab />
          </IconButton>
          {user && (
            <Link to="/">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  marginLeft: "0.9rem",
                }}
              >
                <img
                  src={logo}
                  alt="Static Logo"
                  style={{
                    height: "45px",
                    width: "auto",
                  }}
                />
              </Box>
            </Link>
          )}
          {!user && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginLeft: "0.9rem",
              }}
            >
              <div className="logo-bkoffice">
                <Link to="/">back/house</Link>
              </div>
            </Box>
          )}
        </Toolbar>
      </header>
    </>
  );

  return (
    <>
      {renderAppBar()}
      {renderDrawer()}
    </>
  );
};

export default Nav;
