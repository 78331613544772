export const US_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
export const franchiseOptions = [
  "BW",
  "CHOICE",
  "HILTON",
  "IHG",
  "G6",
  "MARRIOTT",
  "WYNDHAM",
  "INDEPENDENT",
];
export const brandOptions = [
  "AC",
  "ABVI",
  "ALOFT",
  "AMERICINN",
  "ATWELL SUITES",
  "AVID",
  "BAYMONT",
  "BEST WESTERN",
  "CAMBRIA",
  "CANDLEWOOD SUITES",
  "COMFORT INN",
  "COMFORT SUITES",
  "COUNTRY INN & SUITES",
  "COURTYARD",
  "DAYS INN",
  "EMBASSY SUITES",
  "FAIRFIELD",
  "HAMPTON",
  "HILTON",
  "HILTON GARDEN INN",
  "HOLIDAY INN",
  "HOLIDAY INN EXPRESS",
  "HOME2",
  "HOMEWOOD SUITES",
  "INDEPENDENT",
  "LA QUINTA",
  "MICROTEL",
  "MOTEL 6",
  "QUALITY INN",
  "RENAISSANCE",
  "RESIDENCE INN",
  "SPRINGHILL SUITES",
  "SURESTAY",
  "SUPER 8",
  "TOWNEPLACE SUITES",
  "WYNDHAM GARDEN",
];
