import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  arrayUnion,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import OrgChart from "react-orgchart";
import "react-orgchart/index.css";
import axios from "axios";
import {
  TextField,
  Box,
  Button,
  Typography,
  Autocomplete,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import * as XLSX from "xlsx";
import { FaFileExcel } from "react-icons/fa";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import Lottie from "lottie-react";
import animationData from "../../assets/loading.json";
import { PiGarageBold, PiBuildingsBold } from "react-icons/pi";
import { MdOutlinePerson3, MdExpandCircleDown } from "react-icons/md";
import { useAuth } from "../../contexts/AuthContext";
import { FaShareAlt } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";

const Orgchart = () => {
  const { user, userPermissions, userRole, availableProperties } = useAuth();
  const [lookupId, setLookupId] = useState(""); // ID to lookup
  const [searchType, setSearchType] = useState(""); // Type of search: entity, individual, operating
  const [orgData, setOrgData] = useState(null); // Data for the org chart
  const [propertyNames, setPropertyNames] = useState([]); // List of property names for operating entities
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [menuOptions, setMenuOptions] = useState([]);
  const [isMenu, setIsMenu] = useState(true);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [ownershipData, setOwnershipData] = useState([]);
  const [operatingOwnershipData, setOperatingOwnershipData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(
    () => new Date().getFullYear() - 1,
  );
  const [operatingEntityId, setOperatingEntityId] = useState("");
  const [propertyCode, setPropertyCode] = useState([]);
  const [setPermissionsForProperty] = useState({});
  const [selectedPropertyCode] = useState("CHD");
  const [expandedRows, setExpandedRows] = useState({});
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedIndividual, setSelectedIndividual] = useState(null);
  const [shareEmail, setShareEmail] = useState("");
  const [existingAuthorizedUsers, setExistingAuthorizedUsers] = useState([]);
  const [newName, setNewName] = useState("");
  const [shareWithRelatedRecords, setShareWithRelatedRecords] = useState(false);

  // PERMISSIONS

  useEffect(() => {
    if (!propertyCode || !userPermissions[propertyCode]) return;

    // Avoid setting state if permissions haven't changed to prevent re-renders
    const propertyPermissions = userPermissions[propertyCode].permissions || {};
    const permissionsArray = Object.keys(propertyPermissions).filter(
      (key) => propertyPermissions[key] === true,
    );

    setPermissionsForProperty((prevPermissions) => {
      // Only update state if permissions have changed
      const permissionsChanged =
        JSON.stringify(prevPermissions) !== JSON.stringify(permissionsArray);
      return permissionsChanged ? permissionsArray : prevPermissions;
    });
  }, [propertyCode, userPermissions]);

  // Function to determine if a user can view a section
  const canViewSection = (permissions = [], propertyCode) => {
    const requiredPermissions = Array.isArray(permissions)
      ? permissions
      : [permissions];

    // Grant access to Global, MGMT, and PropertyAdmin roles
    if (["Global", "MGMT"].includes(userRole)) return true;

    // Check specific permissions for the property
    return requiredPermissions.every((permission) => {
      const propertyPermissions =
        userPermissions[propertyCode]?.permissions || {};
      return propertyPermissions[permission] === true;
    });
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setIsLoading(true);
        const collectionName =
          searchType === "entity" || searchType === "individual"
            ? "holdMembers"
            : "holdOperating";

        const snapshot = await getDocs(collection(db, collectionName));
        const fetchedOptions = [];
        const fetchedPropertyNames = [];

        if (searchType === "operating") {
          // For operating search, build a mapping of eid to property names.
          const entityPropertyMap = new Map();

          snapshot.forEach((doc) => {
            const data = doc.data();
            const eid = data.eid || "Unknown EID";
            const entityName = data.operatingEntity || "Unknown Entity";
            const propertyName = data.propertyName || "Unknown Property";
            const address = data.address || "Unknown Address";

            if (!entityPropertyMap.has(eid)) {
              entityPropertyMap.set(eid, {
                name: entityName,
                properties: [],
              });
            }

            entityPropertyMap.get(eid).properties.push({
              propertyName,
              address,
            });

            // For UI, push property names with addresses.
            fetchedPropertyNames.push(`${propertyName} (${address})`);
          });

          entityPropertyMap.forEach((value, eid) => {
            fetchedOptions.push({
              id: eid,
              name: `${value.name} (${value.properties.map((p) => p.propertyName).join(", ")})`,
            });
          });
        } else {
          snapshot.forEach((doc) => {
            const data = doc.data();
            if (searchType === "entity" && data.type === "ENTITY") {
              fetchedOptions.push({ id: doc.id, name: data.memberName });
            } else if (
              searchType === "individual" &&
              data.type === "INDIVIDUAL"
            ) {
              const lastFourId = doc.id.slice(-4); // Get the last 4 characters of the ID
              fetchedOptions.push({
                id: doc.id,
                name: `${data.memberName} (${lastFourId})`,
              });
            }
          });
        }

        // Deduplicate options by ID.
        const uniqueOptions = new Map();
        fetchedOptions.forEach((option) => {
          if (!uniqueOptions.has(option.id)) {
            uniqueOptions.set(option.id, option);
          }
        });
        let filteredOptions = Array.from(uniqueOptions.values());

        // If the user does NOT have Global or MGMT access,
        // filter options by checking the document in "holdMembers" for authorizedUsers.
        if (!["Global", "MGMT"].includes(userRole)) {
          const checkOptionPromises = filteredOptions.map(async (option) => {
            // Attempt to get the corresponding document from "holdMembers"
            const docSnap = await getDoc(doc(db, "holdMembers", option.id));
            if (docSnap.exists()) {
              const data = docSnap.data();
              // Check if authorizedUsers is an array and includes the logged-in user's email.
              if (Array.isArray(data.authorizedUsers)) {
                return data.authorizedUsers.includes(user.email)
                  ? option
                  : null;
              }
            }
            return null;
          });
          const results = await Promise.all(checkOptionPromises);
          filteredOptions = results.filter((opt) => opt !== null);
        }

        // Sort options alphabetically and set them.
        const sortedOptions = filteredOptions.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setOptions(sortedOptions);

        // Sort property names (if applicable) and set them.
        const sortedPropertyNames = fetchedPropertyNames.sort((a, b) =>
          a.localeCompare(b),
        );
        setPropertyNames(sortedPropertyNames);
      } catch (err) {
        console.error("Error fetching options:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [searchType, user, userRole, userPermissions]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setIsLoading(true);
        const collectionName =
          searchType === "entity" || searchType === "individual"
            ? "holdMembers"
            : "holdOperating";

        const snapshot = await getDocs(collection(db, collectionName));
        const fetchedOptions = [];
        const fetchedPropertyNames = []; // To store property names with addresses

        if (searchType === "operating") {
          const entityPropertyMap = new Map();

          snapshot.forEach((doc) => {
            const data = doc.data();
            const eid = data.eid || "Unknown EID";
            const entityName = data.operatingEntity || "Unknown Entity";
            const propertyName = data.propertyName || "Unknown Property";
            const address = data.address || "Unknown Address"; // Fetch the address field

            if (!entityPropertyMap.has(eid)) {
              entityPropertyMap.set(eid, {
                name: entityName,
                properties: [],
              });
            }

            entityPropertyMap.get(eid).properties.push({
              propertyName,
              address,
            });

            // Push property names with addresses for the UI
            fetchedPropertyNames.push(`${propertyName} (${address})`);
          });

          entityPropertyMap.forEach((value, eid) => {
            fetchedOptions.push({
              id: eid,
              name: `${value.name} (${value.properties
                .map((p) => p.propertyName)
                .join(", ")})`,
            });
          });
        } else {
          // For "entity" and "individual", fetch documents from holdMembers
          snapshot.forEach((doc) => {
            const data = doc.data();
            if (searchType === "entity" && data.type === "ENTITY") {
              fetchedOptions.push({ id: doc.id, name: data.memberName });
            } else if (
              searchType === "individual" &&
              data.type === "INDIVIDUAL"
            ) {
              const lastFourId = doc.id.slice(-4); // Get the last 4 characters of the ID
              fetchedOptions.push({
                id: doc.id,
                name: `${data.memberName} (${lastFourId})`,
              });
            }
          });
        }

        // Deduplicate fetched options
        const uniqueOptions = new Map();
        fetchedOptions.forEach((option) => {
          if (!uniqueOptions.has(option.id)) {
            uniqueOptions.set(option.id, option);
          }
        });
        let filteredOptions = Array.from(uniqueOptions.values());

        // If searchType is "entity" or "individual" and the user does not have Global/MGMT access,
        // filter options based on the authorizedUsers field in each document.
        if (
          (searchType === "entity" || searchType === "individual") &&
          !["Global", "MGMT"].includes(userRole)
        ) {
          const checkOptionPromises = filteredOptions.map(async (option) => {
            // Retrieve the document from "holdMembers"
            const docSnap = await getDoc(doc(db, "holdMembers", option.id));
            if (docSnap.exists()) {
              const data = docSnap.data();
              if (Array.isArray(data.authorizedUsers)) {
                return data.authorizedUsers.includes(user.email)
                  ? option
                  : null;
              }
            }
            return null;
          });
          const results = await Promise.all(checkOptionPromises);
          filteredOptions = results.filter((opt) => opt !== null);
        }

        // Sort options alphabetically and set them
        const sortedOptions = filteredOptions.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setOptions(sortedOptions);

        // Sort and set property names with addresses
        const sortedPropertyNames = fetchedPropertyNames.sort((a, b) =>
          a.localeCompare(b),
        );
        setPropertyNames(sortedPropertyNames);
      } catch (err) {
        console.error("Error fetching options:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [searchType, user, userRole, userPermissions]);

  const fetchOwnershipData = async (id) => {
    if (searchType === "entity") {
      await fetchEntityData(id);
    } else if (searchType === "individual") {
      await fetchIndividualData(id, selectedYear);
    } else if (searchType === "operating") {
      setOperatingEntityId(id);
      console.log("Operating Entity ID:", id);
      await fetchOperatingEntityData(id);
    } else {
      // Fallback in case searchType is not one of the above:
      console.warn("Unrecognized searchType. Defaulting to entity fetch.");
      await fetchEntityData(id);
    }
  };

  const fetchEntityData = async (id) => {
    setIsLoading(true);
    setError("");
    try {
      const rootDoc = await getDoc(doc(db, "holdMembers", id));
      if (!rootDoc.exists()) {
        setError("ID not found in holdMembers.");
        return;
      }

      const rootNode = {
        id,
        name: rootDoc.data().memberName || "Unknown",
        type: rootDoc.data().type,
        children: [],
      };

      const visited = new Set();
      await buildHierarchyForEntity(rootNode, visited);

      const flattenedData = flattenOrgChart(rootNode);
      setOwnershipData(flattenedData);
      setOrgData(rootNode);
    } catch (err) {
      console.error("Error fetching entity data:", err);
      setError(err.message || "An error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchIndividualData = async (id, year) => {
    setIsLoading(true);
    setError("");
    try {
      const rootDoc = await getDoc(doc(db, "holdMembers", id));
      if (!rootDoc.exists()) {
        setError("ID not found in holdMembers.");
        setIsLoading(false);
        return;
      }

      console.log("📆 fetchIndividualData received year:", year);
      if (!year) {
        console.error(
          "❌ selectedYear is undefined inside fetchIndividualData.",
        );
        return;
      }

      const individualData = rootDoc.data();

      // Include involvedEntities from the fetched document
      const rootNode = {
        id,
        name: individualData.memberName || "Unknown",
        type: individualData.type, // e.g. "INDIVIDUAL"
        involvedEntities: individualData.involvedEntities || [],
        children: [],
      };

      // 1) Build the direct entity hierarchy using the data we already have.
      await buildHierarchyForIndividual(rootNode);

      // 2) Flatten if you want it in a table
      const flattenedData = flattenOrgChart(rootNode);
      setOwnershipData(flattenedData);

      // 3) Store hierarchical data for your org chart
      setOrgData(rootNode);

      // ✅ Fetch operating-based table data; this function can also make use of involvedEntities if desired.
      await fetchOperatingOwnershipForIndividual(id, year);
    } catch (err) {
      console.error("Error fetching individual data:", err);
      setError(err.message || "An error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOperatingOwnershipForIndividual = async (
    individualId,
    selectedYear,
  ) => {
    try {
      const response = await fetch(
        "https://us-central1-doorman-1985.cloudfunctions.net/getOperatingOwnership",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ individualId, year: selectedYear }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch operating ownership data");
      }

      const data = await response.json();
      setOperatingOwnershipData(data);
    } catch (error) {
      console.error("❌ Error fetching operating ownership:", error);
    }
  };

  const fetchOperatingEntityData = async (eid) => {
    setIsLoading(true);
    setError("");
    try {
      const operatingQuery = query(
        collection(db, "holdOperating"),
        where("eid", "==", eid),
      );
      const operatingSnapshot = await getDocs(operatingQuery);

      if (operatingSnapshot.empty) {
        setError("No operating companies found for the given Entity ID.");
        setIsLoading(false);
        return;
      }

      const properties = [];
      const rootNode = {
        id: eid,
        name: `Operating Entity: ${eid}`,
        type: "OPERATING",
        children: [],
      };

      operatingSnapshot.forEach((doc) => {
        const operatingData = doc.data();
        properties.push(operatingData.propertyName || "Unknown Property");
      });

      // Set unique property names above the chart
      setPropertyNames([...new Set(properties)]);

      // Treat operating entity as a regular entity for the org chart
      await buildHierarchyForEntity(rootNode);

      const flattenedData = flattenOrgChart(rootNode);
      setOwnershipData(flattenedData);

      setOrgData(rootNode);
    } catch (err) {
      console.error("Error fetching operating entity data:", err);
      setError(err.message || "An error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const buildHierarchyForEntity = async (node, visited = new Set()) => {
    try {
      if (visited.has(node.id)) return;
      visited.add(node.id);

      const docSnap = await getDoc(doc(db, "holdMembers", node.id));
      if (!docSnap.exists()) return;
      const data = docSnap.data();

      if (Array.isArray(data.owners)) {
        for (const owner of data.owners) {
          const ownerNode = {
            id: owner.id,
            name: owner.name,
            type: owner.type || "UNKNOWN",
            ownership: owner.ownershipPercentage,
            children: [],
          };
          node.children.push(ownerNode);

          // For entities, always try to go deeper.
          if (owner.type === "ENTITY") {
            // If there is a subOwners array, process it first.
            if (Array.isArray(owner.subOwners) && owner.subOwners.length > 0) {
              for (const subOwner of owner.subOwners) {
                const subOwnerNode = {
                  id: subOwner.id,
                  name: subOwner.name,
                  type: subOwner.type || "UNKNOWN",
                  ownership: subOwner.ownershipPercentage,
                  children: [],
                };
                ownerNode.children.push(subOwnerNode);
                if (subOwner.type === "ENTITY") {
                  await buildHierarchyForEntity(subOwnerNode, visited);
                }
              }
            } else {
              // Always attempt to fetch the document for the owner node,
              // even if subOwners is not explicitly stored.
              await buildHierarchyForEntity(ownerNode, visited);
            }
          }
        }
      }
    } catch (err) {
      console.error(`Error building hierarchy for entity ${node.id}:`, err);
    }
  };

  const buildHierarchyForIndividual = async (node, visited = new Set()) => {
    try {
      if (visited.has(node.id)) return;
      visited.add(node.id);

      // If node already has involvedEntities (set from fetchIndividualData), use them.
      const involvedEntities = node.involvedEntities;
      if (Array.isArray(involvedEntities) && involvedEntities.length > 0) {
        for (const ent of involvedEntities) {
          const entityNode = {
            id: ent.id,
            name: ent.name,
            type: "ENTITY",
            ownership: ent.ownershipPercentage,
            children: [],
          };
          node.children.push(entityNode);

          // Expand that entity by calling a function (assumed already implemented) to build its hierarchy.
          await buildHierarchyForEntity(entityNode, visited);
        }
      } else {
        // Otherwise, if you prefer to fetch the individual document again, you can do so here.
        const individualDoc = await getDoc(doc(db, "holdMembers", node.id));
        if (!individualDoc.exists()) return;

        const individualData = individualDoc.data();
        if (Array.isArray(individualData.involvedEntities)) {
          for (const ent of individualData.involvedEntities) {
            const entityNode = {
              id: ent.id,
              name: ent.name,
              type: "ENTITY",
              ownership: ent.ownershipPercentage,
              children: [],
            };
            node.children.push(entityNode);

            await buildHierarchyForEntity(entityNode, visited);
          }
        }
      }
    } catch (err) {
      console.error(`Error building hierarchy for individual ${node.id}:`, err);
    }
  };

  const triggerDataFetch = async (id) => {
    try {
      setIsLoading(true);
      setError("");
      console.log("📆 Triggering data fetch for year:", selectedYear);
      await fetchOwnershipData(id);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const flattenOrgChart = (
    node,
    parent = null,
    relationship = "Root",
    parentExposure = 1,
    depth = 0,
  ) => {
    const flatData = [];
    const exposure =
      parentExposure * (node.ownership ? node.ownership / 100 : 1);
    flatData.push({
      id: node.id,
      name: node.name,
      type: node.type,
      ownership: node.ownership || null,
      exposure,
      relationship,
      parent: parent ? parent.id : null,
      isEntity: node.type === "ENTITY",
      depth,
      financialData: node.financialData || null,
    });
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        flatData.push(
          ...flattenOrgChart(
            child,
            node,
            `${child.type} under ${node.name}`,
            exposure,
            depth + 1,
          ),
        );
      });
    }
    return flatData;
  };

  const formatCurrency = (value) => {
    if (value === undefined || value === null || isNaN(parseFloat(value)))
      return "N/A";

    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(value));
  };

  const formatPercentage = (value) => {
    if (value === undefined || value === null || isNaN(value)) return "N/A";
    return `${(value * 100).toFixed(2)}%`; // Convert decimal to percentage
  };

  const formatRatio = (value) => {
    if (value === undefined || value === null || isNaN(value)) return "N/A";

    const numericValue = parseFloat(value); // Convert string to number if needed
    if (isNaN(numericValue)) return "N/A"; // Double-check for NaN cases

    return numericValue.toFixed(2); // Ensure two decimal places
  };

  // Function to open the Share Access dialog for the selected individual.
  const handleShareClick = (individual) => {
    console.log("handleShareClick called with:", individual);
    // If no individual is passed, warn and exit.
    if (!individual) {
      console.warn("No individual available for sharing access.");
      return;
    }
    setSelectedIndividual(individual);
    setShareDialogOpen(true);
  };

  // Function to update the individual's authorizedUsers array.

  useEffect(() => {
    const fetchExistingAuthorizedUsers = async () => {
      if (selectedIndividual) {
        try {
          const docSnap = await getDoc(
            doc(db, "holdMembers", selectedIndividual.id),
          );
          if (docSnap.exists()) {
            const data = docSnap.data();
            setExistingAuthorizedUsers(
              Array.isArray(data.authorizedUsers) ? data.authorizedUsers : [],
            );
          }
        } catch (error) {
          console.error("Error fetching authorizedUsers:", error);
        }
      }
    };

    fetchExistingAuthorizedUsers();
  }, [selectedIndividual]);

  const handleShareAccess = async () => {
    console.log(
      "handleShareAccess called. shareEmail:",
      shareEmail,
      "selectedIndividual:",
      selectedIndividual,
      "newName:",
      newName,
    );
    try {
      if (!selectedIndividual || !shareEmail || !newName) {
        console.warn(
          "Missing selectedIndividual, shareEmail, or newName. Cannot share access.",
        );
        return;
      }

      // Hardcode propertyCode as "CHD"
      const propertyCode = "CHD";

      // If the user wants to share with related records, check that the logged-in user
      // already has access on all related records.
      if (
        shareWithRelatedRecords &&
        orgData &&
        Array.isArray(orgData.children) &&
        !canViewSection("portfolioAdmin", selectedPropertyCode)
      ) {
        const recsWithoutAccess = [];
        for (const rec of orgData.children) {
          // Get the document for each related record.
          const recSnap = await getDoc(doc(db, "holdMembers", rec.id));
          if (recSnap.exists()) {
            const recData = recSnap.data();
            const authorized = Array.isArray(recData.authorizedUsers)
              ? recData.authorizedUsers.map((email) => email.toLowerCase())
              : [];
            if (!authorized.includes(user.email.toLowerCase())) {
              recsWithoutAccess.push(rec.id);
            }
          }
        }
        if (recsWithoutAccess.length > 0) {
          alert(
            "You do not currently have access to all related records. " +
              "You cannot share full access with related records unless you have access to each one.",
          );
          return;
        }
      }

      // References
      const userRef = doc(db, "emailAuthUsers", shareEmail.toLowerCase());
      const empRegisterRef = doc(db, "Properties", propertyCode);
      const webhookUrl =
        "https://prod-158.westus.logic.azure.com:443/workflows/df0a9547f10244f2b63c482e577fd4a9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ltx6ZfWiLkZ8bBctvl8KlOjsQsKhqspdcGeVNz0yY04";

      let userUid = null;
      let tempPassword = null;

      // Check if the user exists in emailAuthUsers.
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        userUid = userData.uid;
        // Trigger webhook for updating roles for an existing user.
        await axios.post(webhookUrl, {
          trigger: "portfolioAccess",
          oldEmail: user.email,
          newEmail: shareEmail.toLowerCase(),
          code: propertyCode,
          oldPhoneNumber: selectedIndividual?.name,
          newPhoneNumber: "",
          message: newName,
        });
        console.log(
          "Webhook triggered for existing Firestore user:",
          shareEmail,
        );
      } else {
        // User doesn't exist; create a new user with a temporary password.
        tempPassword = generateTempPassword();
        const { uid, message } = await createNewUserWithTempPassword(
          shareEmail,
          newName,
          tempPassword,
        );
        userUid = uid;
        console.log(`User ${message}:`, shareEmail);

        // Mark email as verified.
        await fetch(
          "https://us-central1-doorman-1985.cloudfunctions.net/markEmailVerifiedAndUpdateUser",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ uid, email: shareEmail.toLowerCase() }),
          },
        );

        // Trigger webhook for a new user.
        await axios.post(webhookUrl, {
          trigger: "newPortfolioAccess",
          oldEmail: user.email,
          newEmail: shareEmail.toLowerCase(),
          code: tempPassword,
          oldPhoneNumber: selectedIndividual?.name,
          newPhoneNumber: "",
          message: newName,
        });
        console.log("Webhook triggered for new user:", shareEmail);
      }

      alert("Adding user, please wait...");

      // Update (or create) the user document in emailAuthUsers.
      await setDoc(
        userRef,
        {
          propertyRoles: {
            ["CHD"]: {
              PORTFLIO: true,
              portLookupEntityChart: true,
              portLookupIndividualChart: true,
              portLookupOperatingChart: false,
              role: "FinViewer",
              portSeePersonalFinancial: true,
            },
          },
          name: newName,
          uid: userUid,
          ...(tempPassword ? { tempPassword } : {}), // include tempPassword only if defined
        },
        { merge: true },
      );

      // Update permissions in the Properties collection.
      await updateDoc(empRegisterRef, {
        permissions: arrayUnion(shareEmail.toLowerCase()),
      });

      // Update the selected individual's document in holdMembers by adding the email.
      await updateDoc(doc(db, "holdMembers", selectedIndividual.id), {
        authorizedUsers: arrayUnion(shareEmail.toLowerCase()),
      });
      console.log(
        "Access successfully shared for individual:",
        selectedIndividual.id,
      );

      // Optionally update related records if the user chose to share with them.
      if (
        shareWithRelatedRecords &&
        orgData &&
        Array.isArray(orgData.children)
      ) {
        for (const rec of orgData.children) {
          if (rec.id !== selectedIndividual.id) {
            await updateDoc(doc(db, "holdMembers", rec.id), {
              authorizedUsers: arrayUnion(shareEmail.toLowerCase()),
            });
            console.log("Access shared for related record:", rec.id);
          }
        }
      }

      // Add a note to the property.
      await addNoteToProperty(propertyCode, {
        date: Timestamp.now(),
        user: user.email,
        action: userSnap.exists()
          ? `Updated user ${shareEmail} with access for ${selectedIndividual.name}`
          : `Added user ${shareEmail} with access for ${selectedIndividual.name}`,
      });

      // Close the dialog.
      handleDialogClose();
    } catch (error) {
      console.error("Error sharing access:", error);
    }
  };

  const generateTempPassword = () => {
    return Math.floor(100000000 + Math.random() * 900000000).toString(); // Generate a 9-digit password
  };

  const createNewUserWithTempPassword = async (
    shareEmail,
    displayName,
    tempPassword,
  ) => {
    try {
      const manageUserResponse = await fetch(
        "https://us-central1-doorman-1985.cloudfunctions.net/manageUserByEmail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: shareEmail.toLowerCase(),
            displayName,
            password: tempPassword,
          }),
        },
      );

      if (!manageUserResponse.ok) {
        const error = await manageUserResponse.json();
        throw new Error(
          error.message ||
            "Failed to create or manage user with temporary password",
        );
      }

      return await manageUserResponse.json();
    } catch (error) {
      console.error("Error in createNewUserWithTempPassword:", error);
      throw error;
    }
  };

  const addNoteToProperty = async (note) => {
    const propertyRef = doc(db, "Properties", "CHD");
    try {
      await updateDoc(propertyRef, {
        Notes: arrayUnion(note),
      });
      console.log("Note added to property.");
    } catch (error) {
      console.error("Error adding note to property:", error);
    }
  };

  // const handleAddNewUser = async () => {
  //   if (!shareEmail || !selectedIndividual) return;
  //
  //   try {
  //     // Hardcode propertyCode as "CHD"
  //     const propertyCode = "CHD";
  //     const userRef = doc(db, "emailAuthUsers", shareEmail.toLowerCase());
  //     const empRegisterRef = doc(db, "Properties", propertyCode);
  //     const webhookUrl =
  //       "https://prod-158.westus.logic.azure.com:443/workflows/df0a9547f10244f2b63c482e577fd4a9/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ltx6ZfWiLkZ8bBctvl8KlOjsQsKhqspdcGeVNz0yY04";
  //     let userUid = null;
  //     let tempPassword = null;
  //
  //     // Omit fetching AdCity and AdState from the current property
  //
  //     // Check if the user exists in Firestore
  //     const userSnap = await getDoc(userRef);
  //
  //     if (userSnap.exists()) {
  //       const userData = userSnap.data();
  //
  //       // User exists in Firestore but lacks property roles
  //       userUid = userData.uid;
  //
  //       // Trigger the webhook for updating roles
  //       await axios.post(webhookUrl, {
  //         trigger: "EmailAuthUserProp",
  //         oldEmail: "",
  //         newEmail: shareEmail.toLowerCase(),
  //         code: propertyCode,
  //         oldPhoneNumber: "",
  //         newPhoneNumber: "",
  //         message: newName,
  //       });
  //
  //       console.log(
  //         "Webhook triggered for existing Firestore user:",
  //         shareEmail,
  //       );
  //     } else {
  //       // User doesn't exist in Firestore; use the Cloud Function
  //       tempPassword = generateTempPassword();
  //       const { uid, message } = await createNewUserWithTempPassword(
  //         shareEmail,
  //         newName,
  //         tempPassword,
  //       );
  //       userUid = uid;
  //
  //       console.log(`User ${message}:`, shareEmail);
  //
  //       // Mark email as verified
  //       await fetch(
  //         "https://us-central1-doorman-1985.cloudfunctions.net/markEmailVerifiedAndUpdateUser",
  //         {
  //           method: "POST",
  //           headers: { "Content-Type": "application/json" },
  //           body: JSON.stringify({ uid, email: shareEmail.toLowerCase() }),
  //         },
  //       );
  //
  //       // Send webhook for new user
  //       await axios.post(webhookUrl, {
  //         trigger: "newEmailAuthUser",
  //         oldEmail: "",
  //         newEmail: shareEmail.toLowerCase(),
  //         code: tempPassword,
  //         oldPhoneNumber: "",
  //         newPhoneNumber: "",
  //         message: newName,
  //       });
  //
  //       console.log("Webhook triggered for new user:", shareEmail);
  //     }
  //
  //     alert("Adding user, please wait...");
  //
  //     // Add or update the user's roles in Firestore.
  //     // Notice that we no longer set AdCity or AdState.
  //     await setDoc(
  //       userRef,
  //       {
  //         name: newName,
  //         uid: userUid,
  //         tempPassword, // Save the temporary password only if a new user was created
  //       },
  //       { merge: true },
  //     );
  //
  //     // Update the permissions in the Properties collection.
  //     await updateDoc(empRegisterRef, {
  //       permissions: arrayUnion(shareEmail.toLowerCase()),
  //     });
  //
  //     // Add a note to the property.
  //     await addNoteToProperty(propertyCode, {
  //       date: Timestamp.now(),
  //       user: user.email,
  //       action: userSnap.exists()
  //         ? `Updated user ${shareEmail} with access to ${searchType} access for ${selectedIndividual}`
  //         : `Added user ${shareEmail} with access to ${searchType} access for ${selectedIndividual}`,
  //     });
  //   } catch (error) {}
  // };

  // Handle closing the share dialog.
  const handleDialogClose = () => {
    console.log("Closing Share Access dialog");
    setShareDialogOpen(false);
    setShareEmail("");
    // Optionally, you might keep the selectedIndividual in state or clear it.
    // setSelectedIndividual(null);
  };

  const OperatingOwnershipTable = ({ data }) => {
    const [expandedRows, setExpandedRows] = useState({});
    const [exportAnchorEl, setExportAnchorEl] = useState(null); // For export menu

    if (!Array.isArray(data) || data.length === 0) {
      return null;
    }

    // Group operating ownership rows by viaEntity.
    const grouped = data.reduce((acc, row) => {
      const groupKey = row.viaEntity ? row.viaEntity.id : "direct";
      if (!acc[groupKey]) {
        acc[groupKey] = {
          viaEntity: row.viaEntity,
          rows: [],
        };
      }
      acc[groupKey].rows.push(row);
      return acc;
    }, {});

    const groups = Object.values(grouped);

    // Toggle function for row expansion
    const toggleRowExpansion = (groupId) => {
      setExpandedRows((prev) => ({
        ...prev,
        [groupId]: !prev[groupId], // Toggle state
      }));
    };

    // Function to format data and export as Excel
    const exportToExcel = (includeAllRows) => {
      const formattedData = [];

      groups.forEach((group) => {
        const headerRow = {
          "Group Name": group.viaEntity
            ? group.viaEntity.name
            : "Direct Ownership",
          "Ownership %": group.viaEntity
            ? group.viaEntity.ownershipPercentage
            : group.rows[0].ownershipPercentage,
          "Appraised Valuation": "",
          "Stated Valuation": "",
          "Sales Revenue": "",
          "Net Income": "",
          Debt: "",
          "LTV (%)": "",
          NOI: "",
          DSCR: "",
        };
        formattedData.push(headerRow);

        if (includeAllRows) {
          group.rows.forEach((row) => {
            formattedData.push({
              "Property Name": row.propertyName,
              "Ownership %": row.ownershipPercentage?.toFixed(2),
              "Appraised Valuation": formatCurrency(
                row.financialData?.appraisedValue,
              ),
              "Stated Valuation": formatCurrency(
                row.financialData?.statedValuation,
              ),
              "Sales Revenue": formatCurrency(row.financialData?.salesRevenue),
              "Net Income": formatCurrency(row.financialData?.netIncome),
              Debt: formatCurrency(row.financialData?.debt),
              "LTV (%)": formatPercentage(row.financialData?.ltv),
              NOI: formatCurrency(row.financialData?.noi),
              DSCR: formatRatio(row.financialData?.dscr),
            });
          });
        }
      });

      // Create and download Excel file
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(formattedData);
      XLSX.utils.book_append_sheet(wb, ws, "Operating Ownership");
      XLSX.writeFile(wb, "Operating_Ownership.xlsx");
    };

    return (
      <>
        <TableContainer
          component={Paper}
          sx={{ marginTop: "0.9rem", overflowX: "auto" }}
        >
          <Table sx={{ width: "100%", maxHeight: "500px", overflow: "auto" }}>
            <TableHead sx={{ top: 0, backgroundColor: "white" }}>
              <TableRow>
                <TableCell align="left" sx={maintitle200header}>
                  <IconButton
                    onClick={(e) => setExportAnchorEl(e.currentTarget)}
                    sx={{
                      fontSize: "1.35rem",
                      color: "#93A683",
                      backgroundColor: "transparent",
                      padding: "9.9px",
                      border: "2.97px solid #93A683",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <FaFileExcel />
                  </IconButton>
                  <Menu
                    anchorEl={exportAnchorEl}
                    open={Boolean(exportAnchorEl)}
                    onClose={() => setExportAnchorEl(null)}
                    sx={{ borderRadius: "9px", padding: "1rem" }}
                  >
                    <MenuItem
                      sx={{ ...selectMenuItem }}
                      onClick={() => exportToExcel(false)}
                    >
                      ENTITIES
                    </MenuItem>
                    <MenuItem
                      sx={{ ...selectMenuItem }}
                      onClick={() => exportToExcel(true)}
                    >
                      ENTITIES + HOTELS
                    </MenuItem>
                  </Menu>
                </TableCell>
                <TableCell align="left" sx={maintitle200header}>
                  (%)
                </TableCell>

                <Tooltip title="Appraised Valuation">
                  <TableCell align="left" sx={maintitle200header}>
                    AV
                  </TableCell>
                </Tooltip>
                <Tooltip title="Stated Valuation">
                  <TableCell align="left" sx={maintitle200header}>
                    SV
                  </TableCell>
                </Tooltip>
                <Tooltip title="Sales Revenue">
                  <TableCell align="left" sx={maintitle200header}>
                    REV
                  </TableCell>
                </Tooltip>
                <Tooltip title="Net Income">
                  <TableCell align="left" sx={maintitle200header}>
                    NI
                  </TableCell>
                </Tooltip>
                <Tooltip title="Mortgage Balance">
                  <TableCell align="left" sx={maintitle200header}>
                    MORT
                  </TableCell>
                </Tooltip>
                <Tooltip title="Loan-to-value">
                  <TableCell align="left" sx={maintitle200header}>
                    LTV
                  </TableCell>
                </Tooltip>
                <Tooltip title="Net Operating Income">
                  <TableCell align="left" sx={maintitle200header}>
                    NOI
                  </TableCell>
                </Tooltip>
                <Tooltip title="Debt-service coverage ratio">
                  <TableCell align="left" sx={maintitle200header}>
                    DSCR
                  </TableCell>
                </Tooltip>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.map((group, groupIndex) => {
                const headerName = group.viaEntity
                  ? group.viaEntity.name
                  : "Direct Ownership";
                const headerOwnershipPercentage = group.viaEntity
                  ? group.viaEntity.ownershipPercentage
                  : group.rows[0].ownershipPercentage;

                // Calculate aggregated financials for this group by reducing the rows.
                const aggregatedFinancial = group.rows.reduce(
                  (agg, row) => {
                    // Use row's ownership percentage as a weight (if provided), otherwise default to 1.
                    const factor = row.ownershipPercentage
                      ? row.ownershipPercentage / 100
                      : 1;
                    agg.statedValuation +=
                      (row.financialData?.statedValuation || 0) * factor;
                    agg.appraisedValue +=
                      (row.financialData?.appraisedValue || 0) * factor;
                    agg.salesRevenue +=
                      (row.financialData?.salesRevenue || 0) * factor;
                    agg.netIncome +=
                      (row.financialData?.netIncome || 0) * factor;
                    agg.debt += (row.financialData?.debt || 0) * factor;
                    agg.noi += (row.financialData?.noi || 0) * factor;

                    // For LTV, accumulate weighted sum and weight.
                    const ltv = row.financialData?.ltv || 0;
                    agg.ltvWeightedSum += ltv * factor;
                    agg.ltvTotalWeight += factor;

                    // For DSCR, accumulate weighted sum and weight.
                    const dscr = row.financialData?.dscr || 0;
                    agg.dscrWeightedSum += dscr * factor;
                    agg.dscrTotalWeight += factor;

                    return agg;
                  },
                  {
                    statedValuation: 0,
                    appraisedValue: 0,
                    salesRevenue: 0,
                    netIncome: 0,
                    debt: 0,
                    noi: 0,
                    ltvWeightedSum: 0,
                    ltvTotalWeight: 0,
                    dscrWeightedSum: 0,
                    dscrTotalWeight: 0,
                  },
                );

                // Calculate weighted averages for LTV and DSCR.
                aggregatedFinancial.ltv =
                  aggregatedFinancial.ltvTotalWeight > 0
                    ? aggregatedFinancial.ltvWeightedSum /
                      aggregatedFinancial.ltvTotalWeight
                    : 0;
                aggregatedFinancial.dscr =
                  aggregatedFinancial.dscrTotalWeight > 0
                    ? aggregatedFinancial.dscrWeightedSum /
                      aggregatedFinancial.dscrTotalWeight
                    : 0;

                return (
                  <React.Fragment key={groupIndex}>
                    {/* Group Header Row with Expand/Collapse Button and aggregated financials */}
                    <TableRow>
                      <TableCell
                        sx={{ ...maintableBold, textTransform: "uppercase" }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            onClick={() => toggleRowExpansion(groupIndex)}
                          >
                            {expandedRows[groupIndex] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#202426",
                              fontFamily: "Outfit",
                              letterSpacing: "0.045rem",
                              fontWeight: "800",
                              fontSize: "0.9rem",
                            }}
                          >
                            {headerName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#202426",
                            fontFamily: "Outfit",
                            letterSpacing: "0.045rem",
                            fontWeight: "800",
                            fontSize: "0.9rem",
                          }}
                        >
                          {headerOwnershipPercentage.toFixed(2)}%
                        </Typography>
                      </TableCell>
                      <TableCell align="left" size="small">
                        {formatCurrency(aggregatedFinancial.statedValuation)}
                      </TableCell>
                      <TableCell align="left" size="small">
                        {formatCurrency(aggregatedFinancial.appraisedValue)}
                      </TableCell>
                      <TableCell align="left" size="small">
                        {formatCurrency(aggregatedFinancial.salesRevenue)}
                      </TableCell>
                      <TableCell align="left" size="small">
                        {formatCurrency(aggregatedFinancial.netIncome)}
                      </TableCell>
                      <TableCell align="left" size="small">
                        {formatCurrency(aggregatedFinancial.debt)}
                      </TableCell>
                      <TableCell align="left" size="small">
                        {/*{formatPercentage(aggregatedFinancial.ltv)}*/}
                      </TableCell>
                      <TableCell align="left" size="small">
                        {formatCurrency(aggregatedFinancial.noi)}
                      </TableCell>
                      <TableCell align="left" size="small">
                        {/*{formatRatio(aggregatedFinancial.dscr)}*/}
                      </TableCell>
                    </TableRow>

                    {/* Operating ownership rows for this group (Only show when expanded) */}
                    {expandedRows[groupIndex] &&
                      group.rows.map((row, index) => {
                        // Calculate the scaling factor from ownership percentage.
                        const factor = row.ownershipPercentage
                          ? row.ownershipPercentage / 100
                          : 1;
                        return (
                          <TableRow key={`${row.eid}-${index}`}>
                            {canViewSection(
                              "portSeePersonalFinancial",
                              selectedPropertyCode,
                            ) && (
                              <>
                                <TableCell align="left" size="small">
                                  {row.propertyName}
                                </TableCell>
                                <TableCell align="left" size="small">
                                  {row.ownershipPercentage?.toFixed(2)}
                                </TableCell>
                                <TableCell align="left" size="small">
                                  {formatCurrency(
                                    (row.financialData?.statedValuation || 0) *
                                      factor,
                                  )}
                                </TableCell>
                                <TableCell align="left" size="small">
                                  {formatCurrency(
                                    (row.financialData?.appraisedValue || 0) *
                                      factor,
                                  )}
                                </TableCell>
                                <TableCell align="left" size="small">
                                  {formatCurrency(
                                    (row.financialData?.salesRevenue || 0) *
                                      factor,
                                  )}
                                </TableCell>
                                <TableCell align="left" size="small">
                                  {formatCurrency(
                                    (row.financialData?.netIncome || 0) *
                                      factor,
                                  )}
                                </TableCell>
                                <TableCell align="left" size="small">
                                  {formatCurrency(
                                    (row.financialData?.debt || 0) * factor,
                                  )}
                                </TableCell>
                                <TableCell align="left" size="small">
                                  {formatPercentage(row.financialData?.ltv)}{" "}
                                  {/* LTV is typically a ratio */}
                                </TableCell>
                                <TableCell align="left" size="small">
                                  {formatCurrency(
                                    (row.financialData?.noi || 0) * factor,
                                  )}
                                </TableCell>
                                <TableCell align="left" size="small">
                                  {formatRatio(row.financialData?.dscr)}{" "}
                                  {/* DSCR is a ratio */}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const OrgChartTable = ({ ownershipData, searchType = "operating" }) => {
    // Only render if searchType is not "individual"
    if (searchType.includes("individual")) return null;

    // Ensure ownershipData is defined and is an array with at least one element.
    if (!Array.isArray(ownershipData) || ownershipData.length === 0) {
      return null;
    }

    // Sort the flattened data by depth, then by name.
    const sortedData = [...ownershipData].sort(
      (a, b) => a.depth - b.depth || a.name.localeCompare(b.name),
    );

    // Render type icons.
    const renderTypeIcon = (type) => {
      if (type === "ENTITY") {
        return (
          <Box
            sx={{
              backgroundColor: "rgba(242, 183, 10, 0.18)",
              width: "27px",
              height: "27px",
              borderRadius: "50%",
              border: "1.98px solid #F2B70A",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: 1,
            }}
          >
            <IconButton
              style={{
                backgroundColor: "transparent",
                border: "0",
                color: "#F2B70A",
                fontSize: "1.08rem",
              }}
            >
              <PiGarageBold />
            </IconButton>
          </Box>
        );
      } else if (type === "INDIVIDUAL") {
        return (
          <Box
            sx={{
              backgroundColor: "rgba(147, 166, 131, 0.18)",
              width: "27px",
              height: "27px",
              borderRadius: "50%",
              border: "1.98px solid #93A683",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: 1,
            }}
          >
            <IconButton
              style={{
                backgroundColor: "transparent",
                border: "0",
                color: "#93A683",
                fontSize: "1.08rem",
              }}
            >
              <MdOutlinePerson3 />
            </IconButton>
          </Box>
        );
      } else if (type === "OPERATING") {
        return (
          <Box
            sx={{
              backgroundColor: "f9f9f9",
              width: "27px",
              height: "27px",
              borderRadius: "50%",
              border: "1.98px solid black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: 1,
            }}
          >
            <IconButton
              style={{
                backgroundColor: "transparent",
                border: "0",
                color: "#202426",
                fontSize: "1.08rem",
              }}
            >
              <PiBuildingsBold />
            </IconButton>
          </Box>
        );
      }
      return null;
    };

    return (
      <TableContainer
        component={Paper}
        sx={{ marginTop: "0.9rem", overflowX: "auto" }}
      >
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow></TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => {
              // For top-level nodes (depth 0), use the raw ownership value.
              // For deeper nodes, use the exposure value multiplied by 100.
              const displayValue =
                row.depth === 0
                  ? row.ownership
                    ? row.ownership.toFixed(2)
                    : "N/A"
                  : row.exposure
                    ? (row.exposure * 100).toFixed(2)
                    : "100.00";
              return (
                <TableRow
                  key={`${row.id}-${index}`}
                  sx={{ height: "9px", paddingLeft: "1rem" }}
                >
                  <TableCell
                    align="left"
                    sx={{
                      ...maintableBold,
                    }}
                  />
                  <TableCell
                    align="left"
                    sx={{
                      ...maintableBold,
                      paddingLeft: `${row.depth * 27}px`,
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {renderTypeIcon(row.type)}
                    <span>{row.name}</span>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      ...maintableBold,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {displayValue}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      ...maintableBold,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.relationship} {row.parent ? `(${row.parent})` : ""}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const OperatingFinancialsCard = ({ operatingEntityId, selectedYear }) => {
    const [financialData, setFinancialData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
      const fetchFinancialData = async () => {
        setIsLoading(true);
        setError("");
        try {
          // Query holdFinancials for this operating entity and year
          const financialQuery = query(
            collection(db, "holdFinancials"),
            where("eid", "==", operatingEntityId),
            where("year", "==", selectedYear),
          );
          const snapshot = await getDocs(financialQuery);
          if (!snapshot.empty) {
            // Assuming one document per operating entity per year
            setFinancialData(snapshot.docs[0].data());
          } else {
            setFinancialData(null);
          }
        } catch (err) {
          console.error("Error fetching financial data:", err);
          setError("Error fetching financial data.");
        } finally {
          setIsLoading(false);
        }
      };

      if (operatingEntityId && selectedYear) {
        fetchFinancialData();
      }
    }, [operatingEntityId, selectedYear]);

    return (
      <Card sx={{ marginTop: "1rem" }}>
        <CardContent>
          <Grid container alignItems="center" justifyContent="left" spacing={2}>
            <Grid item xs={12} sm={8}>
              <FormControl
                sx={{ ...formControl, maxWidth: "189px", marginLeft: "2.7rem" }}
              >
                <InputLabel sx={{ ...selectInputLabel }}>
                  Select Year
                </InputLabel>
                <Select
                  value={selectedYear} // Always uses main UI year
                  onChange={(e) => setSelectedYear(e.target.value)} // Doesn't affect the dialog
                  label="Select Year"
                  sx={{ ...bh }}
                >
                  {[...Array(new Date().getFullYear() - 2009)].map(
                    (_, index) => {
                      const year = new Date().getFullYear() - index;
                      return (
                        <MenuItem
                          sx={{ ...selectMenuItem }}
                          key={year}
                          value={year}
                        >
                          {year}
                        </MenuItem>
                      );
                    },
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  padding: "0.9rem",
                  borderTopRightRadius: "18px",
                  borderTopLeftRadius: "18px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    letterSpacing: "0.045rem",
                    fontWeight: "800",
                    fontSize: "1.44rem",
                    color: "#202426",
                    textTransform: "lowercase",
                  }}
                  variant="body1"
                  align="left"
                >
                  Financials ({selectedYear})
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Lottie
                  animationData={animationData}
                  style={{ width: "9%", height: "9%" }}
                />
              </Box>
            </Box>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : financialData ? (
            <Table>
              <TableHead>
                <TableRow>
                  <Tooltip title="Appraised Valuation">
                    <TableCell align="left" sx={maintitle200header}>
                      AV
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Stated Valuation">
                    <TableCell align="left" sx={maintitle200header}>
                      SV
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Sales Revenue">
                    <TableCell align="left" sx={maintitle200header}>
                      REV
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Net Income">
                    <TableCell align="left" sx={maintitle200header}>
                      NI
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Depreciation">
                    <TableCell align="left" sx={maintitle200header}>
                      DEP
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Loss Carried Forward">
                    <TableCell align="left" sx={maintitle200header}>
                      LCF
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Mortgage Balance">
                    <TableCell align="left" sx={maintitle200header}>
                      MORT
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Mortgage Payment">
                    <TableCell align="left" sx={maintitle200header}>
                      MP
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Loan-to-value">
                    <TableCell align="left" sx={maintitle200header}>
                      LTV
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Net Operating Income">
                    <TableCell align="left" sx={maintitle200header}>
                      NOI
                    </TableCell>
                  </Tooltip>
                  <Tooltip title="Debt-service coverage ratio">
                    <TableCell align="left" sx={maintitle200header}>
                      DSCR
                    </TableCell>
                  </Tooltip>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="left"
                    size="small"
                    sx={{ ...maintableBold }}
                  >
                    {formatCurrency(financialData.appraisedValue)}
                  </TableCell>
                  {canViewSection(
                    "portViewValuation",
                    selectedPropertyCode,
                  ) && (
                    <TableCell
                      align="left"
                      size="small"
                      sx={{ ...maintableBold }}
                    >
                      {formatCurrency(financialData.statedValuation)}
                    </TableCell>
                  )}
                  <TableCell
                    align="left"
                    size="small"
                    sx={{ ...maintableBold }}
                  >
                    {formatCurrency(financialData.salesRevenue)}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    sx={{ ...maintableBold }}
                  >
                    {formatCurrency(financialData.netIncome)}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    sx={{ ...maintableBold }}
                  >
                    {formatCurrency(financialData.depreciation)}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    sx={{ ...maintableBold }}
                  >
                    {formatCurrency(financialData.lossCarriedForward)}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    sx={{ ...maintableBold }}
                  >
                    {formatCurrency(financialData.debt)}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    sx={{ ...maintableBold }}
                  >
                    {formatCurrency(financialData.debtService)}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    sx={{ ...maintableBold }}
                  >
                    {formatPercentage(financialData.ltv)}{" "}
                    {/* Keep as percentage */}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    sx={{ ...maintableBold }}
                  >
                    {formatCurrency(financialData.noi)}
                  </TableCell>
                  <TableCell
                    align="left"
                    size="small"
                    sx={{ ...maintableBold }}
                  >
                    {formatRatio(financialData.dscr)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginLeft: 3,
                fontFamily: "Outfit",
                fontSize: "1.17rem",
                fontWeight: "400",
                textTransform: "lowercase",
                letterSpacing: "0.036rem",
                color: "grey",
                paddingTop: "1.8rem",
              }}
            >
              No financial data found for this operating entity.
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          borderRadius: "0.9rem",
          marginBottom: "1.8rem",
        }}
      >
        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{ margin: "9px" }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                marginRight: 4.5,
                marginLeft: 1.8,
                marginBottom: 4.5,
                fontFamily: "Outfit",
                letterSpacing: "0.09rem",
                fontWeight: "800",
                fontSize: "2.07rem",
                color: "#202426",
                textTransform: "lowercase",
                position: "relative",
                "::after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: 0.9,
                  width: "144px",
                  height: "2.7px",
                  backgroundColor: "grey",
                },
              }}
              variant="body1"
              align="left"
            >
              Holdings
            </Typography>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "left",
                gap: "0.5rem", // Spacing between capsules
                position: "relative",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              {/*ENTITY BUTTON*/}
              {canViewSection(
                "portLookupEntityChart",
                selectedPropertyCode,
              ) && (
                <Button
                  variant={
                    searchType.includes("entity") ? "contained" : "outlined"
                  }
                  onClick={() => {
                    setNewName("");
                    setInputValue("");
                    setSelectedIndividual("");
                    setLookupId("");
                    setMenuOptions([]);
                    setOrgData(null);
                    setSearchType("entity");
                    setPropertyNames([]);
                    setOwnershipData([]);
                    setError("");
                    setIsLoading(false);
                  }}
                  sx={{
                    minHeight: "54px",
                    borderRadius: "18px",
                    padding: "7.2px 18px",
                    textTransform: "none",
                    border: "2.07px solid black",
                    backgroundColor: searchType.includes("entity")
                      ? "#202426"
                      : "#f9f9f9",
                    color: searchType.includes("entity") ? "#fff" : "#202426",
                    "&:hover": {
                      backgroundColor: searchType.includes("entity")
                        ? "#333"
                        : "#f0f0f0",
                      borderColor: "black",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "4.5px",
                  }}
                >
                  {searchType.includes("entity") ? (
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: "600",
                        color: "#f5f5f5",
                        fontSize: "0.72rem",
                        fontFamily: "Outfit",
                        letterSpacing: "0.045rem",
                        textTransform: "uppercase",
                      }}
                    >
                      ENTITY
                    </Typography>
                  ) : (
                    <>
                      <IconButton
                        style={{
                          backgroundColor: "transparent",
                          border: "0",
                          color: "#202426",
                          fontSize: "1.53rem",
                        }}
                      >
                        <PiGarageBold />
                      </IconButton>
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: "600",
                          color: "black",
                          fontSize: "0.72rem",
                          fontFamily: "Outfit",
                          letterSpacing: "0.045rem",
                          textTransform: "uppercase",
                        }}
                      >
                        ENTITY
                      </Typography>
                    </>
                  )}
                </Button>
              )}
              {/*INDIVIDUAL BUTTON*/}
              {canViewSection(
                "portLookupIndividualChart",
                selectedPropertyCode,
              ) && (
                <Button
                  variant={
                    searchType.includes("individual") ? "contained" : "outlined"
                  }
                  onClick={() => {
                    setNewName("");
                    setInputValue("");
                    setSelectedIndividual("");
                    setLookupId("");
                    setMenuOptions([]);
                    setOrgData(null);
                    setSearchType("individual");
                    setPropertyNames([]);
                    setOwnershipData([]);
                    setError("");
                    setIsLoading(false);
                  }}
                  sx={{
                    minHeight: "54px",
                    borderRadius: "18px",
                    padding: "7.2px 18px",
                    textTransform: "none",
                    border: "2.07px solid black",
                    backgroundColor: searchType.includes("individual")
                      ? "#202426"
                      : "#f9f9f9",
                    color: searchType.includes("individual")
                      ? "#fff"
                      : "#202426",
                    "&:hover": {
                      backgroundColor: searchType.includes("individual")
                        ? "#333"
                        : "#f0f0f0",
                      borderColor: "black",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "4.5px",
                  }}
                >
                  {searchType.includes("individual") ? (
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: "600",
                        color: "#f5f5f5",
                        fontSize: "0.72rem",
                        fontFamily: "Outfit",
                        letterSpacing: "0.045rem",
                        textTransform: "uppercase",
                      }}
                    >
                      INDIVIDUAL
                    </Typography>
                  ) : (
                    <>
                      <IconButton
                        style={{
                          backgroundColor: "transparent",
                          border: "0",
                          color: "#202426",
                          fontSize: "1.53rem",
                        }}
                      >
                        <MdOutlinePerson3 />
                      </IconButton>
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: "600",
                          color: "black",
                          fontSize: "0.72rem",
                          fontFamily: "Outfit",
                          letterSpacing: "0.045rem",
                          textTransform: "uppercase",
                        }}
                      >
                        INDIVIDUAL
                      </Typography>
                    </>
                  )}
                </Button>
              )}
              {/*OPERATING BUTTON*/}
              {canViewSection(
                "portLookupOperatingChart",
                selectedPropertyCode,
              ) && (
                <Button
                  variant={
                    searchType.includes("operating") ? "contained" : "outlined"
                  }
                  onClick={() => {
                    setSelectedIndividual("");
                    setInputValue("");
                    setLookupId("");
                    setMenuOptions([]);
                    setOrgData(null);
                    setSearchType("operating");
                    setPropertyNames([]);
                    setOwnershipData([]);
                    setError("");
                    setIsLoading(false);
                  }}
                  sx={{
                    minHeight: "54px",
                    borderRadius: "18px",
                    padding: "7.2px 18px",
                    textTransform: "none",
                    border: "2.07px solid black",
                    backgroundColor: searchType.includes("operating")
                      ? "#202426"
                      : "#f9f9f9",
                    color: searchType.includes("operating")
                      ? "#fff"
                      : "#202426",
                    "&:hover": {
                      backgroundColor: searchType.includes("operating")
                        ? "#333"
                        : "#f0f0f0",
                      borderColor: "black",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: "4.5px",
                  }}
                >
                  {searchType.includes("operating") ? (
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: "600",
                        color: "#f5f5f5",
                        fontSize: "0.72rem",
                        fontFamily: "Outfit",
                        letterSpacing: "0.045rem",
                        textTransform: "uppercase",
                      }}
                    >
                      OPERATING
                    </Typography>
                  ) : (
                    <>
                      <IconButton
                        style={{
                          backgroundColor: "transparent",
                          border: "0",
                          color: "#202426",
                          fontSize: "1.53rem",
                        }}
                      >
                        <PiBuildingsBold />
                      </IconButton>
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: "600",
                          color: "black",
                          fontSize: "0.72rem",
                          fontFamily: "Outfit",
                          letterSpacing: "0.045rem",
                          textTransform: "uppercase",
                        }}
                      >
                        OPERATING
                      </Typography>
                    </>
                  )}
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={10} sm={5} sx={{ marginTop: 1 }}>
            {/* Input Field or Select Menu */}
            <Autocomplete
              fullWidth
              freeSolo
              options={options}
              getOptionLabel={(option) => option.name || ""}
              loading={isLoading}
              onInputChange={(event, value) => setInputValue(value)}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedIndividual(newValue);
                  triggerDataFetch(newValue.id); // Trigger data fetch for the selected ID
                }
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={`Search by ${
                    searchType === "operating"
                      ? "Entity or Property Name"
                      : "Name or ID"
                  }`}
                  variant="outlined"
                  sx={{ ...bh }}
                />
              )}
              componentsProps={{
                popper: {
                  sx: {
                    "& .MuiAutocomplete-listbox": {
                      fontSize: { xs: "0.81rem" },
                      fontFamily: "Nunito",
                      fontWeight: 400,
                      letterSpacing: "0.045rem",
                      color: "#202426",
                      textTransform: "uppercase",
                      transition: "0.3s",
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: { xs: "0.81rem" },
                      fontFamily: "Nunito",
                      fontWeight: 400,
                      letterSpacing: "0.045rem",
                      color: "#202426",
                      textTransform: "uppercase",
                      transition: "0.3s",
                    },
                  },
                },
              }}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                "& .MuiAutocomplete-popupIndicator": {
                  color: "#202426",
                },
              }}
            />
          </Grid>
          <Grid item xs={2} sm={2} sx={{ marginTop: 1 }}>
            {searchType === "individual" && (
              <IconButton
                onClick={() => handleShareClick(selectedIndividual)}
                sx={{
                  fontSize: "1.35rem",
                  color: "black",
                  backgroundColor: "transparent",
                  padding: "9.9px",
                  border: "2px solid black",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <FaShareAlt />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>

      <Card
        sx={{
          borderRadius: "18px",
          padding: 1.26,
          marginBottom: "1.8rem",
          marginTop: "2.7rem",
          backgroundColor: "#fcfcfc",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden", // Prevent unwanted content from spilling outside the card
        }}
      >
        <Grid
          container
          sx={{
            padding: "0.9rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12} // Ensure it spans the full width
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              justifyContent: "center",
              overflow: "hidden", // Ensure content stays within the grid
            }}
          >
            <Box sx={{ backgroundColor: "#f5f5f5", padding: "0.9rem" }}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  letterSpacing: "0.09rem",
                  fontWeight: "800",
                  fontSize: "2.07rem",
                  color: "#202426",
                  textTransform: "lowercase",
                }}
                variant="body1"
                align="left"
              >
                org chart
              </Typography>
            </Box>

            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Lottie
                  animationData={animationData}
                  style={{ width: "9%", height: "9%" }}
                />
              </Box>
            ) : error ? (
              <p style={{ color: "red" }}>{error}</p>
            ) : orgData ? (
              <>
                <Box sx={{ textAlign: "center", marginBottom: "1rem" }}>
                  {propertyNames.length > 0 && (
                    <Card>
                      <ul>
                        <Typography
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: "1.17rem",
                            fontWeight: "800",
                            textTransform: "lowercase",
                            letterSpacing: "0.045rem",
                            marginBottom: "0.45rem",
                          }}
                          align="left"
                        >
                          Properties:
                        </Typography>
                        {propertyNames.map((name, index) => (
                          <Typography
                            key={index}
                            sx={{
                              marginTop: 0.45,
                              fontFamily: "Outfit",
                              letterSpacing: "0.045rem",
                              fontWeight: "400",
                              fontSize: "0.9rem",
                              textTransform: "uppercase",
                            }}
                            align="left"
                          >
                            {name}
                          </Typography>
                        ))}
                      </ul>
                    </Card>
                  )}
                </Box>

                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    overflowX: "auto", // Enable horizontal scrolling for wide org charts
                    width: "99%",
                  }}
                >
                  <OrgChart
                    tree={orgData}
                    NodeComponent={({ node }) => (
                      <Box
                        style={{
                          padding: "5.4px",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.5rem",
                          }}
                        >
                          {node.type === "ENTITY" ? (
                            <Box
                              sx={{
                                backgroundColor: "rgba(242, 183, 10, 0.18)",
                                width: "36px",
                                height: "36px",
                                borderRadius: "50%",
                                border: "2.0052px solid #F2B70A",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton
                                style={{
                                  backgroundColor: "transparent",
                                  border: "0",
                                  color: "#F2B70A",
                                  fontSize: "1.35rem",
                                }}
                              >
                                <PiGarageBold />
                              </IconButton>
                            </Box>
                          ) : node.type === "INDIVIDUAL" ? (
                            <Box
                              sx={{
                                backgroundColor: "rgba(147, 166, 131, 0.18)",
                                width: "30.6px",
                                height: "30.6px",
                                borderRadius: "50%",
                                border: "2.0052px solid #93A683",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <IconButton
                                style={{
                                  backgroundColor: "transparent",
                                  border: "0",
                                  color: "#93A683",
                                  fontSize: "1.35rem",
                                }}
                              >
                                <MdOutlinePerson3 />
                              </IconButton>
                            </Box>
                          ) : node.type === "OPERATING" ? (
                            <Box
                              sx={{
                                backgroundColor: "#f2f2f2",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                                border: "2.0052px solid black",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                marginBottom: "0.45rem",
                              }}
                            >
                              <IconButton
                                style={{
                                  backgroundColor: "transparent",
                                  border: "0",
                                  color: "#202426",
                                  fontSize: "1.62rem",
                                }}
                              >
                                <PiBuildingsBold />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Box>
                        <Box
                          sx={{
                            paddingLeft: "0.63rem",
                            paddingRight: "0.63rem",
                          }}
                        >
                          <Typography
                            sx={{
                              marginTop: 0.45,
                              fontFamily: "Outfit",
                              letterSpacing: "0.045rem",
                              fontWeight: "400",
                              fontSize: "0.9rem",
                              textTransform: "uppercase",
                            }}
                            align="center"
                          >
                            {node.name}
                          </Typography>
                        </Box>
                        {node.ownership && (
                          <Typography
                            sx={{
                              fontFamily: "Outfit",
                              fontSize: "0.9rem",
                              fontWeight: "800",
                              color: "black",
                            }}
                          >
                            {" "}
                            {node.ownership}%
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Box>
              </>
            ) : (
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  marginLeft: 3,
                  fontFamily: "Outfit",
                  fontSize: "1.17rem",
                  fontWeight: "400",
                  textTransform: "lowercase",
                  letterSpacing: "0.036rem",
                  color: "grey",
                  paddingTop: "1.8rem",
                }}
              >
                No data to display
              </Typography>
            )}
          </Grid>
        </Grid>
      </Card>
      {searchType == "operating" && (
        <Card
          sx={{
            borderRadius: "18px",
            padding: "1.26rem",
            marginBottom: "1.8rem",
            backgroundColor: "#fcfcfc",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12}>
            <Accordion
              sx={{
                overflowX: "auto",
                width: "100%",
                backgroundColor: "#f9f9f9",
                marginTop: "3.6rem",
                marginBottom: "3.6rem",
                border: "3.6px solid transparent",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <IconButton
                    style={{
                      backgroundColor: "transparent",
                      border: "0",
                      color: "black",
                      fontSize: "1.8rem",
                    }}
                  >
                    <MdExpandCircleDown />
                  </IconButton>
                }
                sx={{
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                  "&.Mui-expanded": {
                    borderBottomLeftRadius: "9px",
                    borderBottomRightRadius: "9px",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "1.44rem",
                    fontWeight: "800",
                    textTransform: "lowercase",
                    letterSpacing: "0.045rem",
                    color: "#202426",
                    marginLeft: "0.45rem",
                  }}
                >
                  beneficial ownership (%)
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  borderBottomLeftRadius: "9px",
                  borderBottomRightRadius: "9px",
                  "&.Mui-expanded": {
                    borderBottomLeftRadius: "9px",
                    borderBottomRightRadius: "9px",
                  },
                }}
              >
                {/* OrgChart in table form */}
                <OrgChartTable ownershipData={ownershipData} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Card>
      )}
      <Card
        sx={{
          borderRadius: "18px",
          padding: "1.26rem",
          marginBottom: "1.8rem",
          backgroundColor: "#fcfcfc",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* operating financials */}
        {searchType === "operating" && (
          <OperatingFinancialsCard
            operatingEntityId={operatingEntityId}
            selectedYear={selectedYear}
          />
        )}
        {/* operating ownership */}
        {searchType === "individual" && (
          <OperatingOwnershipTable data={operatingOwnershipData} />
        )}
        {searchType === "entity" && (
          <>
            <Box sx={{ backgroundColor: "#f5f5f5", padding: "0.9rem" }}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  letterSpacing: "0.045rem",
                  fontWeight: "800",
                  fontSize: "1.44rem",
                  color: "#202426",
                  textTransform: "lowercase",
                }}
                variant="body1"
                align="left"
              >
                beneficial ownership (%)
              </Typography>
            </Box>
            <OrgChartTable ownershipData={ownershipData} />
          </>
        )}
      </Card>
      {/* Share Access Dialog */}
      <Dialog
        open={shareDialogOpen}
        onClose={handleDialogClose}
        fullWidth
        PaperProps={{
          sx: { ...dialogMain },
        }}
      >
        <Box sx={{ ...dialogBox }}>
          <IconButton onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle sx={{ ...dialogTitle }}>Share Access:</DialogTitle>
        <DialogContent sx={{ ...dialogContent }}>
          <Typography
            variant="body1"
            sx={{
              color: "#202426",
              fontFamily: "Outfit",
              letterSpacing: "0.045rem",
              fontWeight: "800",
              fontSize: "0.99rem",
              textTransform: "uppercase",
              marginBottom: "0.9rem",
            }}
          >
            {selectedIndividual?.propertyName ||
              selectedIndividual?.name ||
              "Unknown"}
          </Typography>
          <Grid container alignItems="center" justifyContent="left" spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shareWithRelatedRecords}
                    onChange={(e) =>
                      setShareWithRelatedRecords(e.target.checked)
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: "#FFC107",
                      },
                    }}
                  />
                }
                label={
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        display: "inline",
                        color: "#202426",
                        fontFamily: "Outfit",
                        letterSpacing: "0.036rem",
                        fontWeight: "800",
                        fontSize: "0.81rem",
                        textTransform: "uppercase",
                      }}
                    >
                      Share access to related org charts
                    </Typography>
                    {orgData &&
                    orgData.children &&
                    orgData.children.length > 0 ? (
                      <Box>
                        {orgData.children.map((rec, idx) => (
                          <Typography
                            key={idx}
                            variant="body2"
                            sx={{
                              color: "#202426",
                              fontFamily: "Outfit",
                              letterSpacing: "0.036rem",
                              fontWeight: "400",
                              fontSize: "0.81rem",
                              textTransform: "uppercase",
                            }}
                          >
                            {rec.name} (ID: {rec.id})
                          </Typography>
                        ))}
                      </Box>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        None.
                      </Typography>
                    )}
                  </>
                }
              />
            </Grid>

            {searchType.includes("individual") && (
              <>
                <Grid item xs={4}>
                  <TextField
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    required
                    sx={{ ...bh }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={shareEmail}
                    onChange={(e) => setShareEmail(e.target.value)}
                    sx={{ ...bh }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button onClick={handleShareAccess} sx={{ ...greenButton }}>
                    Share
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#202426",
                  fontFamily: "Outfit",
                  letterSpacing: "0.045rem",
                  fontWeight: "800",
                  fontSize: "0.9rem",
                  textTransform: "uppercase",
                  marginTop: "0.9rem",
                }}
              >
                Current access:
              </Typography>
              {existingAuthorizedUsers.length > 0 ? (
                <Box sx={{ mb: 2 }}>
                  {existingAuthorizedUsers.map((email, index) => (
                    <Typography
                      key={index}
                      sx={{
                        color: "#202426",
                        fontFamily: "Outfit",
                        letterSpacing: "0.045rem",
                        fontWeight: "400",
                        fontSize: "0.9rem",
                        textTransform: "lowercase",
                      }}
                    >
                      {email}
                    </Typography>
                  ))}
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 2 }}
                >
                  None.
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Orgchart;

const maintitle200header = {
  fontWeight: 800,
  fontSize: "0.9rem",
  fontFamily: "Outfit",
  letterSpacing: "0.045rem",
  backgroundColor: "#f5f5f5",
  color: "#202426",
  textTransform: "uppercase",
};
const maintable = {
  textTransform: "lowercase",
  backgroundColor: "#fcfcfc",
  color: "#202426",
  rowHeight: "0.1rem",
  letterSpacing: "0.036rem",
  fontWeight: "400",
  fontSize: "0.9rem",
};
const maintableBold = {
  textTransform: "lowercase",
  backgroundColor: "#fcfcfc",
  color: "#202426",
  rowHeight: "0.1rem",
  fontFamily: "Outfit",
  letterSpacing: "0.045rem",
  fontWeight: "800",
  fontSize: "0.9rem",
};
const bh = {
  color: "#202426",
  height: "54px",
  fontFamily: "Nunito",
  textTransform: "uppercase",
  backgroundColor: "rgba(255, 255, 255, 0.63)",
  borderRadius: "9px",
  transition: "background-color 0.3s ease, border-color 0.3s ease",

  "& .MuiAutocomplete-inputRoot, & .MuiOutlinedInput-root": {
    height: "100%",
    fontSize: "0.9rem",
    textTransform: "uppercase",
    color: "#202426",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    borderRadius: "inherit",
    transition: "background-color 0.3s ease, border-color 0.3s ease",
  },
  ".MuiInputLabel-root": {
    fontSize: "0.81rem",
    fontFamily: "Outfit",
    fontWeight: 400,
    color: "#202426",
    transition: "0.3s",
    textTransform: "uppercase",
  },

  ".MuiInputBase-input": {
    fontFamily: "Nunito",
    fontWeight: 400,
    letterSpacing: "0.018rem",
    color: "black",
    transition: "0.3s",
    textTransform: "uppercase",
  },

  "& .MuiOutlinedInput-root.Mui-focused": {
    backgroundColor: "#ffffff",
    borderColor: "#202426",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.45)",
    borderRadius: "9px",
  },
  "&:hover": {
    backgroundColor: "#f2f2f2", // Subtle hover effect
    color: "#202426",
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },

  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },

  "& .MuiSelect-select": {
    padding: "11px 14px", // Align padding with text fields
    color: "#202426",
    fontSize: "0.99rem",
  },

  "& .MuiSelect-icon": {
    color: "#202426",
  },
};

const formControl = {
  width: "100%", // Ensures full width
  height: "54px", // Matches `bh` height
  backgroundColor: "rgba(255, 255, 255, 0.63)",
  borderRadius: "9px",
  transition: "background-color 0.3s ease, border-color 0.3s ease",

  "& .MuiOutlinedInput-root": {
    height: "100%", // Ensures consistency
    fontSize: "0.9rem",
    textTransform: "uppercase",
    color: "#202426",
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    borderRadius: "inherit",
    transition: "background-color 0.3s ease, border-color 0.3s ease",
  },

  "& .MuiOutlinedInput-root.Mui-focused": {
    backgroundColor: "rgba(255, 255, 255, 0.63)",
    borderColor: "#202426",
  },

  "& .MuiInputLabel-root": {
    fontSize: "0.81rem",
    fontFamily: "Outfit",
    fontWeight: 400,
    color: "#202426",
    transition: "color 0.3s ease, transform 0.3s ease",
    textTransform: "uppercase",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.45)",
    borderRadius: "9px",
  },

  "&:hover": {
    backgroundColor: "#f2f2f2",
    color: "#202426",
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },

  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },

  "& .MuiSelect-select": {
    padding: "11px 14px", // Align padding with text fields
    color: "#202426",
    fontSize: "0.99rem",
  },

  "& .MuiSelect-icon": {
    color: "#202426",
  },
};

const selectInputLabel = {
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  fontWeight: 400,
  color: "#202426",
  textTransform: "uppercase",
  transition: "color 0.3s ease, transform 0.3s ease",

  "&.Mui-focused": {
    color: "#202426", // Keep the label color on focus
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.45)",
    borderRadius: "9px",
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#202426",
  },
};

const selectMenuItem = {
  fontSize: "0.9rem",
  fontFamily: "Nunito",
  fontWeight: 400,
  letterSpacing: "0.045rem",
  color: "#202426",
  textTransform: "uppercase",
  transition: "color 0.3s ease, background-color 0.3s ease",
  borderRadius: "9px",

  "&:hover": {
    backgroundColor: "#f2f2f2",
    color: "#202426",
  },

  "&.Mui-selected": {
    backgroundColor: "#e0e0e0 !important",
    color: "#202426",
  },

  "&.Mui-focusVisible": {
    backgroundColor: "#e0e0e0",
    outline: "none",
  },
};
const dialogMain = {
  padding: "1rem",
  borderRadius: "36px",
  backgroundColor: "#f5f5f5",
};
const dialogBox = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  top: 9,
  right: 9,
  backgroundColor: "#f5f5f5",
  padding: "0.9rem",
};
const dialogTitle = {
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  fontWeight: "800",
  fontSize: "2.07rem",
  textTransform: "lowercase",
  color: "black",
  backgroundColor: "transparent",
  borderRadius: "18px",
};
const dialogContent = {
  fontFamily: "Outfit",
  letterSpacing: "0.4rem",
  fontWeight: "400",
  fontSize: "1rem",
};
const greenButton = {
  borderRadius: "9px",
  padding: "18px",
  margin: "9px",
  fontWeight: "400",
  fontSize: "0.81rem",
  fontFamily: "Outfit",
  letterSpacing: "0.09rem",
  backgroundColor: "#93A683",
  color: "white",
  "&:hover": {
    backgroundColor: "#9DA65D",
    color: "white",
  },
};
